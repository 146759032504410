import React, { useEffect, useState } from "react";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import ReactFC from "react-fusioncharts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { ChartBarDataModel, ChartBarModel } from "../bar/chart-bar.model";

// https://www.fusioncharts.com/dev/chart-attributes/column2d
ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

export class CategoriesChartModel {
  seriesname?: string;
  data: ChartBarDataModel[] = [];
}

interface ChartBarComponentProps {
  model?: CategoriesChartModel[];
  palettecolors?: string;
}



const MssplineComponent: React.FC<ChartBarComponentProps> = ({
  model,
  palettecolors = "#ff0000",
}) => {
  const [chartConfigs, setChartConfigs] = useState<any>();

  useEffect(() => {
    setChartConfigs({
      // type: "splinearea",
      type: "msspline",
      width: "100%",
      height: 150,
      dataFormat: "json",
      dataSource: {
        chart: {
          theme: "fusion",
          caption: "",
          subCaption: "",
          // xAxisName: "subCaption",
          // yAxisName: "No. of Footfalls",
          // lineThickness: "2",
          // divlineAlpha: "100",
          // divlineColor: "#999999",
          // divlineThickness: "1",
          // divLineIsDashed: "1",
          // divLineDashLen: "0",
          // divLineGapLen: "0",
          // showXAxisLine: "0",
          // xAxisLineThickness: "0",
          showLegend: false,
          xAxisPosition: "top",

          showYAxisValues: false,

          // drawAnchors: false,

          xAxisNameFontSize: "4px",
          // xAxisValueFontColor: "#ff0000",
          xAxisLineThickness: "1",
          xAxisLineColor: "#000000",

          palettecolors: palettecolors,
          drawAnchors: false,

          rotateLabels: false,
          labelFontSize: 10,
          bgColor: "#fafafa",

          numdivlines: "3",
          showvalues: "0",
          legenditemfontsize: "15",
          legenditemfontbold: "1",
        },
        categories: [
          {
            category: [
              {
                label: "1",
              },
              {
                label: "2",
              },
              {
                label: "3",
              },
              {
                label: "4",
              },
              {
                label: "5",
              },
              {
                label: "6",
              },
              {
                label: "today",
              },
            ],
          },
        ],
        dataset: model
      },
    });
  }, [model]);

  return (
    <>
      <ReactFC {...chartConfigs} />
    </>
  );
};

export default MssplineComponent;

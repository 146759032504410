import React from "react";
import {
  IonMenu,
  IonContent,
  IonItem,
  IonRouterOutlet,
  IonIcon,
  IonLabel,
} from "@ionic/react";
import {
  addCircleSharp,
  close,
  exit,
  notifications,
  settings,
} from "ionicons/icons";
import { menuController } from "@ionic/core";
import "./menu.scss";
import SpaceComponent from "../space/space.component";
import { setNotifiation } from "../../store/slice/global.slice";
import { useDispatch } from "react-redux";

export const Menu: React.FC = () => {
  const dispatch = useDispatch();

  const close_menu = () => {
    menuController.close("toolsMenu");
  };
  const openNotification = () => {
    dispatch(setNotifiation(true));
  };
  return (
    <>
      <IonMenu
        className="cursor"
        onClick={close_menu}
        side="start"
        menuId="toolsMenu"
        contentId="menuContent"
      >
        <IonContent className="mobile-p-0" scrollY={false}>
          <IonItem lines="none">
            <IonIcon icon={close} size="large" />
          </IonItem>
          <SpaceComponent space={30} />
          <IonItem lines="none" routerLink="/add-new-structure">
            <IonLabel className="ml-20">Add a new structrue</IonLabel>
            <IonIcon slot="start" icon={addCircleSharp} size="large" />
          </IonItem>
          <IonItem lines="none" onClick={openNotification}>
            <IonLabel className="ml-20">Notifications</IonLabel>
            <IonIcon slot="start" icon={notifications} size="large" />
          </IonItem>
          <IonItem lines="none" routerLink="/settings">
            <IonLabel className="ml-20">Settings</IonLabel>
            <IonIcon slot="start" icon={settings} size="large" />
          </IonItem>
          <IonItem lines="none">
            <IonLabel className="ml-20">Exit</IonLabel>
            <IonIcon slot="start" icon={exit} size="large" />
          </IonItem>
        </IonContent>
      </IonMenu>
      <IonRouterOutlet id="menuContent"></IonRouterOutlet>
    </>
  );
};

import {
  applyMiddleware,
} from '@reduxjs/toolkit';
import { createStore, combineReducers } from 'redux';

import thunk from 'redux-thunk';
import globalSlice from './slice/global.slice';
import smartConcreteSlice from './slice/smart-concrete.slice';


const middleware = applyMiddleware(thunk);

const rootReducer = combineReducers({
  global: globalSlice,
  concrete: smartConcreteSlice
});

// Note: this API requires redux@>=3.1.0
const store = createStore(rootReducer, middleware);

export default store;

import { IonCol, IonIcon, IonImg, IonRouterLink, IonRow } from "@ionic/react";
import { add } from "ionicons/icons";
import React from "react";
import "./home-footer.component.scss";

/**
 * برای گرادینت
 * https://cssgradient.io/
 */
interface ContainerProps {
  callback?: Function;
}

const HomeFooterComponent: React.FC<ContainerProps> = ({ callback }) => {
  const active_action = (name: string) => {
    if (callback) {
      callback(name);
    }
  };
  return (
    <IonRow className="home-footer">
      <IonCol>
        <IonImg
          onClick={() => active_action("search")}
          src="assets/icon/structures/search.svg"
          className="resize cursor"
        />
      </IonCol>
      <IonCol>
        <div className="circle-add cursor" onClick={() => active_action("add")}>
          <IonRouterLink routerLink="/add-new-structure">
            <IonIcon icon={add} size="large" />
          </IonRouterLink>
        </div>
      </IonCol>

      <IonCol>
        <IonImg
          onClick={() => active_action("notifications")}
          src="assets/icon/structures/notifications.svg"
          className="resize cursor"
        />
      </IonCol>
    </IonRow>
  );
};

export default HomeFooterComponent;

import { IonCol, IonIcon, IonRow } from "@ionic/react";
import { settings } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { JSONHelper } from "../../../helpers/json.helper";
import { ConcreteStructureTypeModel } from "../../../models/concrete-structure.model";
import { ConcreteSensorModel } from "../../../models/concrete.model";
import { setToast } from "../../../store/slice/global.slice";
import { concreteState } from "../../../store/slice/smart-concrete.slice";
import { updateStructureRequest } from "../../../store/thunk/smart-concrete.thunk";
import InputTextComponent from "../../forms/input-text/input-text.component";
import SelectComponent from "../../forms/select/select.component";
import { SelectModel } from "../../forms/select/select.model";
import InputAreaComponent from "../../forms/text-area/input-textarea.component";
import SpaceComponent from "../../space/space.component";
import "./sensors-add.component.scss";

interface ContainerProps {
  indexForEdit?: number;
}

const SensorsAddComponent: React.FC<ContainerProps> = ({
  indexForEdit = -1,
}) => {
  const { concrete_structure_data, concrete_structure_selected } = useSelector(
    concreteState
  );

  const [sensorType, setSensorType] = useState("");
  const [sensorName, setSensorName] = useState("");
  const [sensorDescription, setSensorDescription] = useState("");
  const [sensorClass, setSensorClass] = useState("");
  const [sensorData, setSensorData] = useState<number[]>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const checkEditable = () => {
      if (indexForEdit >= 0 && concrete_structure_selected) {
        let item: ConcreteSensorModel =
          concrete_structure_selected.sensors[indexForEdit];
        setSensorType(item.type ?? "");
        setSensorName(item.name ?? "");
        setSensorDescription(item.description ?? "");
        setSensorClass(item.class ?? "");
      }
    }
    checkEditable();
  }, []);

  const onEndedChange = (item: SelectModel) => {
    setSensorType(item.title);
  };

  const onChangeName = (name: string) => {
    setSensorName(name);
  };
  const onChangeDescription = (description: string) => {
    setSensorDescription(description);
  };
  const onEndedChangeClass = (item: SelectModel) => {
    setSensorClass(item.title);
  };

  const addSensor = () => {
    if (!sensorType) {
      dispatch(setToast({ message: "Please enter the Sensor Type:." }));
      return;
    }
    if (!sensorName) {
      dispatch(setToast({ message: "Please enter the name." }));
      return;
    }
    if (!sensorDescription) {
      dispatch(setToast({ message: "Please enter the description." }));
      return;
    }
    if (!sensorClass) {
      dispatch(setToast({ message: "Please enter type." }));
      return;
    }

    let concrete_structure = { ...concrete_structure_selected };

    if (!concrete_structure.sensors) {
      concrete_structure.sensors = [];
    }

    let param = {};
    if (indexForEdit >= 0) {
      let sensor = { ...concrete_structure.sensors[indexForEdit] };
      sensor.type = sensorType;
      sensor.name = sensorName;
      sensor.description = sensorDescription;
      sensor.class = sensorClass;

      let sensors = [...concrete_structure.sensors];
      sensors[indexForEdit] = sensor;

      // update login
      param = {
        ...concrete_structure_selected,
        sensors: sensors,
      };

      // return;
    } else {
      param = {
        ...concrete_structure_selected,
        sensors: [
          ...concrete_structure.sensors,
          {
            type: sensorType,
            name: sensorName,
            description: sensorDescription,
            class: sensorClass,
            data: sensorData,
            date_create: Date.now(),
            see: false
          },
        ],
      };
    }

    // dispatch(updateSelectConcrete(param));
    dispatch(updateStructureRequest(param));
  };

  // useEffect(() => {
  // }, [isEditable])

  const onChange = (e: any) => {
    let jsonHelper = new JSONHelper();
    jsonHelper
      .convertJsonFileToParsJson(e.target.files)
      .then((response) => {
        if (response) {
          setSensorData(response);
        }
      })
      .catch(() => {
        dispatch(setToast({ message: e, status: false }));
      });
  };

  return (
    <div className="sensors-add-style">
      <IonRow className="flex">
        <IonCol size="4" className="label">
          Sensor Type:
        </IonCol>
        <IonCol className="">
          {concrete_structure_data && concrete_structure_data.sensor_type && (
            <SelectComponent
              // hasImage={false}
              isActionSheet={true}
              title=""
              value={new SelectModel(-1, sensorType ?? "")}
              onEndedChange={onEndedChange}
              list={concrete_structure_data.sensor_type.map(
                (item: ConcreteStructureTypeModel) =>
                  new SelectModel(item.id ?? -1, item.title ?? "")
              )}
            />
          )}
        </IonCol>
      </IonRow>
      <SpaceComponent space={10} />
      <IonRow className="flex">
        <IonCol size="4" className="label">
          Name:
        </IonCol>
        <IonCol className="">
          <InputTextComponent
            value={sensorName}
            onEndedChange={onChangeName}
            title=""
          />
        </IonCol>
      </IonRow>
      <SpaceComponent space={10} />
      <IonRow className="flex">
        <IonCol size="4" className="label">
          Description:
        </IonCol>
        <IonCol className="">
          <InputAreaComponent
            value={sensorDescription}
            onEndedChange={onChangeDescription}
            row={2}
          />
        </IonCol>
      </IonRow>
      <SpaceComponent space={10} />
      <IonRow className="flex">
        <IonCol size="4" className="label">
          Type:
        </IonCol>
        <IonCol className="">
          {concrete_structure_data && concrete_structure_data.sensor_class && (
            <SelectComponent
              // hasImage={false}
              isActionSheet={true}
              title=""
              value={new SelectModel(-1, sensorClass ?? "")}
              onEndedChange={onEndedChangeClass}
              list={concrete_structure_data.sensor_class.map(
                (item: ConcreteStructureTypeModel) =>
                  new SelectModel(item.id ?? -1, item.title ?? "")
              )}
            />
          )}
        </IonCol>
      </IonRow>
      <div className="panel-add">
        <IonRow className="content flex">
          <IonCol>
            {/* <div className="button-reset">Reset</div> */}
            <label>
              <p className="s-size">
                <IonIcon icon={settings} /> Settings and Connections
              </p>
              <input
                id="choseFile"
                type="file"
                onChange={onChange}
                accept=".json"
                style={{ display: "none" }}
              />
            </label>
          </IonCol>
          <IonCol size="5">
            <div className="button-black cursor" onClick={addSensor}>
              {indexForEdit === -1 ? "Add" : "Edit"}
            </div>
          </IonCol>
        </IonRow>
      </div>
    </div>
  );
};

export default SensorsAddComponent;

import React, { useEffect, useState } from "react";
import "./view-structure.scss";
import SpaceComponent from "../../../components/space/space.component";
import SelectComponent from "../../../components/forms/select/select.component";
import DateComponent from "../../../components/forms/date/date.component";
import InputAreaComponent from "../../../components/forms/text-area/input-textarea.component";
import ViewerFieldComponent from "../../../components/forms/viewer/viewer-field.component";
import MapComponent from "../../../components/map/map.component";
import SensorsGridComponent from "../../../components/sensors/sensors-grid/sensors.grid.component";
import { useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { concreteState } from "../../../store/slice/smart-concrete.slice";
import { history } from "../../../App";
import InputTextComponent from "../../../components/forms/input-text/input-text.component";
import {
  getStructureRequest,
  updateStructureRequest,
} from "../../../store/thunk/smart-concrete.thunk";
import { concreteStructureTypeList } from "../../../store/thunk/concrete-structure-type.thunk";
import ModalComponent from "../../../components/modal/modal.component";
import { ConcreteStructureTypeModel } from "../../../models/concrete-structure.model";
import ImgTitleRowComponent from "../../../components/forms/img-title-row/img-title-row.component";
import { ConcreteModel } from "../../../models/concrete.model";

const ViewStructurePage = () => {
  const [modalStructureType, setModalStructureType] = useState(false);
  const params = useParams<{ id: string }>();

  const { concrete_structure_selected, concrete_structure_data } = useSelector(
    concreteState
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const request = () => {
      if (!params || !params.id) {
        dispatch(history.goBack());
        return;
      }
  
      dispatch(getStructureRequest(params.id));
      dispatch(concreteStructureTypeList());  
    }
    request();
  }, []);

  /**
   * ------------------------------------------------------------------------------------
   * block name
   */
  const [isNameEditMode, setIsNameEditMode] = useState(false);

  /**
   * برای سوییچ کردن مابین فیلد نمایش و ذخیره
   * @param status
   */
  const onEditModeName = (status = true) => {
    setIsNameEditMode(status);
  };
  /**
   * زمانی که روی دکمه ذخیره در فیلد نام کلیک شود وارد این بلاک میشویم و نام از کامپوننت فرزند منتقل می‌شود
   * @param name
   */
  const onSaveName = (name: string) => {
    if (name) {
      let param = {
        ...concrete_structure_selected,
        name: name,
      };
      updateConcrete(param);
    }
    setIsNameEditMode(false);
  };
  // END Name ------------------------------------------------------------------------------------

  /**
   * ------------------------------------------------------------------------------------
   * block type
   */
  const [isTypeEditMode, setIsTypeEditMode] = useState(false);
  // const [type, setType] = useState("");
  const onEditModeType = (status = true) => {
    setIsTypeEditMode(status);
  };
  const onSaveType = (type: ConcreteStructureTypeModel) => {
    if (type) {
      // setName(type);
      let param = {
        ...concrete_structure_selected,
        type: type,
      };
      updateConcrete(param);
    }
    setIsTypeEditMode(false);
  };

  const updateConcrete = (param: ConcreteModel) => {
    dispatch(updateStructureRequest(param));
  };

  // END type ------------------------------------------------------------------------------------

  /**
   * ------------------------------------------------------------------------------------
   * block date
   */
  const [isDateEditMode, setIsDateEditMode] = useState(false);
  // const [date, setDate] = useState("2020/09/10");
  const onEditModeDate = (status = true) => {
    setIsDateEditMode(status);
  };
  const onSaveDate = (date: string) => {
    if (date) {
      // setDate(date);
      let param = {
        ...concrete_structure_selected,
        date: date,
      };
      updateConcrete(param);
    }
    setIsDateEditMode(false);
  };
  // END type ------------------------------------------------------------------------------------

  /**
   * ------------------------------------------------------------------------------------
   * block description
   */
  const [isDescriptionEditMode, setIsDescriptionEditMode] = useState(false);
  // const [description, setDescription] = useState(
  //   "It is located in a crowded region."
  // );
  const onEditModeDescription = (status = true) => {
    setIsDescriptionEditMode(status);
  };
  const onSaveDescription = (description: string) => {
    if (description) {
      // setDescription(description);
      let param = {
        ...concrete_structure_selected,
        description: description,
      };
      updateConcrete(param);
    }
    setIsDescriptionEditMode(false);
  };
  // END type ------------------------------------------------------------------------------------

  /**
   * ------------------------------------------------------------------------------------
   * block Map
   */
  const [isMapEditMode, setIsMapEditMode] = useState(false);
  // const [map, setMap] = useState("The bridge is over the Sepidrood river.");
  const onEditModeMap = (status = true) => {
    setIsMapEditMode(status);
  };
  const onSaveMap = (map: string) => {
    if (map) {
      // setMap(map);
      let param = {
        ...concrete_structure_selected,
        map: map,
      };
      updateConcrete(param);
    }
    setIsMapEditMode(false);
  };
  // END type ------------------------------------------------------------------------------------

  /**
   * ------------------------------------------------------------------------------------
   * block necessoryInfo
   */
  const [isNecessoryInfoEditMode, setIsNecessoryInfoEditMode] = useState(false);
  // const [necessoryInfo, setNecessoryInfo] = useState(
  //   "The bridge is over the Sepidrood river."
  // );
  const onEditModeNecessoryInfo = (status = true) => {
    setIsNecessoryInfoEditMode(status);
  };
  const onSaveNecessoryInfo = (necessoryInfo: string) => {
    if (necessoryInfo) {
      // setNecessoryInfo(necessoryInfo);
      let param = {
        ...concrete_structure_selected,
        info: necessoryInfo,
      };
      updateConcrete(param);
    }
    setIsNecessoryInfoEditMode(false);
  };
  // END type ------------------------------------------------------------------------------------

  return (
    <>
      {/* <HeaderComponent title="Sepid Bridge" isBack={true} /> */}

      {/* <IonButton
          onClick={(e: any) => {
            e.persist();
            setShowPopover({ showPopover: true, event: e });
          }}
        >
          Show Popover
        </IonButton> */}

      <div className="support_desktop">
        <SpaceComponent space={20} />

        {/* -------------------------------------------------- */}
        {/* handle name editable */}
        {isNameEditMode ? (
          <InputTextComponent
            onSave={onSaveName}
            value={
              concrete_structure_selected && concrete_structure_selected.name
            }
          />
        ) : (
          <ViewerFieldComponent
            description="Name:"
            value={
              concrete_structure_selected && concrete_structure_selected.name
            }
            onEditMode={onEditModeName}
          />
        )}
        {/* -------------------------------------------------- */}

        <SpaceComponent space={40} />

        {/* -------------------------------------------------- */}
        {/* handle type editable */}
        {isTypeEditMode ? (
          <>
            {concrete_structure_selected && (
              <SelectComponent
                onEndedChange={() => setModalStructureType(true)}
                value={{
                  icon: concrete_structure_selected.type.icon,
                  title: concrete_structure_selected.type.title,
                  id: concrete_structure_selected.type.id,
                }}
              />
            )}
            <ModalComponent
              showModal={modalStructureType}
              onDidDismiss={() => setModalStructureType(false)}
              Component={
                concrete_structure_data &&
                concrete_structure_data.structure_type.map(
                  (item: ConcreteStructureTypeModel, index: number) => {
                    return (
                      <ImgTitleRowComponent
                        id={index}
                        key={index}
                        title={item.title}
                        image_src={item.icon}
                        onClick={() => onSaveType(item)}
                      />
                    );
                  }
                )
              }
            />
          </>
        ) : (
          <ViewerFieldComponent
            description="Type:"
            value={
              concrete_structure_selected &&
              concrete_structure_selected.type.title
            }
            onEditMode={onEditModeType}
            image_src={
              concrete_structure_selected &&
              concrete_structure_selected.type.icon
            }
          />
        )}
        {/* -------------------------------------------------- */}

        <SpaceComponent space={40} />

        {/* -------------------------------------------------- */}
        {/* handle name editable */}
        {isDateEditMode ? (
          <DateComponent
            onSave={onSaveDate}
            value={
              concrete_structure_selected && concrete_structure_selected.date
            }
          />
        ) : (
          <ViewerFieldComponent
            description="Date of concreting:"
            value={
              concrete_structure_selected && concrete_structure_selected.date
            }
            onEditMode={onEditModeDate}
          />
        )}
        {/* -------------------------------------------------- */}

        <SpaceComponent space={40} />

        {/* -------------------------------------------------- */}
        {/* handle name editable */}
        {isDescriptionEditMode ? (
          <InputAreaComponent
            title="Description"
            onSave={onSaveDescription}
            value={
              concrete_structure_selected &&
              concrete_structure_selected.description
            }
          />
        ) : (
          <ViewerFieldComponent
            description="Description:"
            value={
              concrete_structure_selected &&
              concrete_structure_selected.description
            }
            onEditMode={onEditModeDescription}
          />
        )}
        {/* -------------------------------------------------- */}

        <SpaceComponent space={40} />

        {/* -------------------------------------------------- */}
        {/* handle name editable */}
        {isMapEditMode ? (
          <MapComponent
            title="Location:"
            onSave={onSaveMap}
            value={
              concrete_structure_selected && concrete_structure_selected.map
            }
          />
        ) : (
          <ViewerFieldComponent
            description="Location:"
            image="assets/map.jpg"
            onEditMode={onEditModeMap}
          />
        )}
        {/* -------------------------------------------------- */}

        <SpaceComponent space={40} />

        {/* -------------------------------------------------- */}
        {/* handle name editable */}
        {isNecessoryInfoEditMode ? (
          <InputAreaComponent
            title="Other necessary info"
            isOptional={true}
            onSave={onSaveNecessoryInfo}
            value={
              concrete_structure_selected && concrete_structure_selected.info
            }
          />
        ) : (
          <ViewerFieldComponent
            description="Other necessary info:"
            value={
              concrete_structure_selected && concrete_structure_selected.info
            }
            onEditMode={onEditModeNecessoryInfo}
          />
        )}
        {/* -------------------------------------------------- */}

        <SpaceComponent space={40} />

        <SensorsGridComponent />

        {/* <div className="panel-add fixed">
          <IonRow className="content">
            <IonCol size="5">
              <div className="button-reset">Reset</div>
            </IonCol>
            <IonCol>
              <div className="button-black">Add</div>
            </IonCol>
          </IonRow>
        </div> */}
      </div>
    </>
  );
};

export default ViewStructurePage;

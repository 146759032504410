import { IonInput } from "@ionic/react";
import React, { useEffect, useState } from "react";
import "./input-text.component.scss";

interface ContainerProps {
  onEndedChange?: Function;
  onChange?: Function;
  onSave?: Function;
  value?: string;
  title?: string;
  placeholder?: string;
}

const InputTextComponent: React.FC<ContainerProps> = ({
  onEndedChange,
  onSave,
  value,
  onChange,
  title = "Name of concrete structure",
  placeholder = "Enter Input",
}) => {

  const [text, setText] = useState<string>();
  
  useEffect(() => {
    setText(value ?? "")
  }, [value])

  const _onChange = (text: string) => {
    setText(text);
    if(onChange) {
      onChange(text)
    }
  };

  const onEnded = () => {
    if (onEndedChange && !onSave) {
      onEndedChange(text);
    }
  };
  const save = () => {
    if (onSave) {
      onSave(text);
    }
  };
  const cancel = () => {
    if (onSave) {
      onSave();
    }
  };
  return (
    <div className={"input-text-style" + (onSave ? " border_edit" : "")}>
      <p className="label">{title ?? ""}</p>
      <IonInput
        value={text}
        placeholder={placeholder}
        onIonChange={(e) => _onChange(e.detail.value!) }
        onIonBlur={onEnded}
      ></IonInput>
      {onSave && (
        // <IonIcon icon={checkmarkCircle} />
        <>
          <a className="button-border save" onClick={save}>save</a>
          <a className="button-border cancel" onClick={cancel}>cancel</a>
        </>
      )}
    </div>
  );
};

export default InputTextComponent;

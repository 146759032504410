import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ConcreteStructureDataModel } from "../../models/concrete-structure.model";
import { ConcreteModel } from "../../models/concrete.model";


export interface SmartConcreteSliceModel {
  concrete_structure_data?: ConcreteStructureDataModel;
  concrete_structure: ConcreteModel[];
  concrete_structure_backup: ConcreteModel[];
  concrete_structure_selected?: ConcreteModel;
}

const initialState: SmartConcreteSliceModel = { concrete_structure: [], concrete_structure_backup: [], concrete_structure_data: undefined};

const SmartConcreteSlice = createSlice({
  name: "SmartConcreteSlice",
  initialState,
  reducers: {
    setConcreteStructureData(state, action: PayloadAction<ConcreteStructureDataModel>) {
      state.concrete_structure_data = action.payload;
    },
    setConcreteList(state, action: PayloadAction<ConcreteModel[]>) {
      state.concrete_structure = action.payload;
    },
    selectConcrete(state, action: PayloadAction<ConcreteModel>) {
      state.concrete_structure_selected = action.payload;
    },
    updateSelectConcrete(state, action: PayloadAction<ConcreteModel>) {
      state.concrete_structure_selected = action.payload;
    },
    unsetSelectConcrete(state) {
      state.concrete_structure_selected = undefined;
    }
  },
});

export const concreteState = (state: any) => state.concrete;

export const { setConcreteStructureData, setConcreteList, selectConcrete, updateSelectConcrete, unsetSelectConcrete } = SmartConcreteSlice.actions;
export default SmartConcreteSlice.reducer;

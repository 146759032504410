import React, { useEffect, useState } from "react";
import { IonRow, IonCol, IonModal, IonIcon } from "@ionic/react";
import "./home.scss";
import PopoverComponent from "../../../components/popover/popover.component";
import ConcreteRowComponent from "../../../components/concrete-row/concrete-row.component";
import SpaceComponent from "../../../components/space/space.component";
import { useDispatch, useSelector } from "react-redux";
import {
  globalState,
  setClearFilter,
  setShowSearch,
} from "../../../store/slice/global.slice";
import { structureListRequest } from "../../../store/thunk/smart-concrete.thunk";
import {
  concreteState,
  setConcreteList,
} from "../../../store/slice/smart-concrete.slice";
import { ConcreteModel } from "../../../models/concrete.model";
import InputTextComponent from "../../../components/forms/input-text/input-text.component";
import { closeCircle, close } from "ionicons/icons";

const HomePage = () => {
  const { concrete_structure } = useSelector(concreteState);
  const { range, showSearch, clearFilter } = useSelector(globalState);
  const dispatch = useDispatch();
  const [filterList, setFilterList] = useState<ConcreteModel[]>([]);
  // const history = useHistory();
  // const [showSearchModal, setShowSearchModal] = useState(false);

  useEffect(() => {
    dispatch(structureListRequest());
  }, []);

  useEffect(() => {
    setFilterList(concrete_structure);
  }, [concrete_structure]);

  const changeSearch = (text: string) => {
    if (text == "") {
      setFilterList(concrete_structure);
      setClearFilter(true);
    } else {
      // setClearFilter();
      setClearFilter(false);
      let filter = concrete_structure.filter((f: ConcreteModel) =>
        f.name?.includes(text)
      );
      setFilterList(filter);
    }
  };

  const _clearFilter = () => {
    dispatch(setClearFilter(true));
    setFilterList(concrete_structure);
  };

  return (
    <>
      <SpaceComponent space={20} />

      <IonRow>
        {filterList && filterList.length > 0 && (
          <IonCol className="c-base-gray pl-5">
            {filterList.length} structures
          </IonCol>
        )}
        <IonCol className="text-right not-allowed">
          {clearFilter == false && (
            <div onClick={_clearFilter}>
              clear filter <IonIcon icon={close} />
            </div>
          )}
          {/* <PopoverComponent
              not_allowed={true}
              list={[
                "apartment",
                "company",
                "factory",
                "hospital",
                "house",
                "store",
              ]}
            /> */}
        </IonCol>
      </IonRow>

      <IonModal
        isOpen={showSearch}
        cssClass="modal_search"
        onDidDismiss={() => dispatch(setShowSearch(false))}
      >
        <div>
          <InputTextComponent
            title=""
            onChange={changeSearch}
            placeholder="search"
          />
        </div>
      </IonModal>

      <div className="">
        {filterList &&
          filterList.map((item: ConcreteModel, index: number) => {
            return (
              <ConcreteRowComponent model={item} key={item.id} range={range} />
            );
          })}
      </div>
      <SpaceComponent space={100} />
    </>
  );
};

export default HomePage;

import React, { useEffect, useState } from "react";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import ReactFC from "react-fusioncharts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

import { ChartBarModel } from "./chart-bar.model";

// https://www.fusioncharts.com/dev/chart-attributes/column2d
ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

interface ChartBarComponentProps {
  model: ChartBarModel;
}

const ChartBarComponent: React.FC<ChartBarComponentProps> = ({ model }) => {
  const [chartConfigs, setChartConfigs] = useState<any>();

  useEffect(() => {
    setChartConfigs({
      type: "column2d",
      width: "100%",
      height: 300,
      dataFormat: "json",
      dataSource: {
        chart: {
          caption: "",
          subCaption: model.subCaption ?? "",
          xAxisName: "",
          yAxisName: "",
          numberSuffix: "",
          palettecolors: "ff96b9",
          theme: "fusion",
          usePlotGradientColor: "1",
          plotGradientColor: "ed135b",
          bgColor: "#fafafa",
          // bgAlpha: 0,

          maxColWidth: 20,
          yAxisMaxValue: model.yAxisMaxValue ?? 100,

          // useRoundEdges: 4
        },
        data: model.data ?? [],
      },
    });
  }, [model]);

  return (
    <>
      <ReactFC {...chartConfigs} />
    </>
  );
};

export default ChartBarComponent;

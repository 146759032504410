
const BASE = "https://smart-concrete.banicaster.com";
// const BASELOCAL = "http://localhost:8000";

// export const BASEURL = BASELOCAL + "/api/";
// export const ASSETSURL = BASELOCAL + "/assets/";

export const BASEURL = BASE + "/api/";
export const ASSETSURL = BASE + "/assets/";


export const insert_url = "insert.php";
export const list_url = "list.php";
export const delete_url = "delete.php";
export const structure_url = "structure.php";
export const update_url = "update.php";

// config
export const update_config_url = "config/update.php";
/**
 * get list concrete_structure_url
 */
export const concrete_structure_url = "concrete-structure/";

import { trendingDownOutline } from "ionicons/icons";
import { SensorTypeRangeModel } from "../models/sensor-type-range.model";

export class JSONHelper {

    error_format: string = "error format json, please enter array<number> format: EX([1,43,2321,23])";
    error_format_config: string = 'error format json, please enter {"humidity":{"min":number,"max":number},"temperature":{"min":number,"max":number},"ph":{"min":number,"max":number}}';

    convertJsonFileToParsJson = (files: string | any[]) => {

        return new Promise<number[]>((resolve, reject) => {
            // var files = e.target.files;
            if (files.length <= 0) {
                reject("not chose file.");
            }

            var reader = new FileReader();
            reader.addEventListener("load", () => {
                try {

                    var result = JSON.parse(reader.result + "");
                    if (Array.isArray(result)) {

                        let status = true;
                        for (const key in result) {

                            if (isNaN(result[key])) {
                                status = false;
                                break;
                            }
                        }
                        status ? resolve(result) : reject(this.error_format);
                    } else reject(this.error_format)
                } catch {
                    reject(this.error_format);
                }
            });
            reader.readAsText(files[0]);
        });



    }


    convertJsonConfig = (files: string | any[]) => {

        return new Promise<SensorTypeRangeModel>((resolve, reject) => {
            // var files = e.target.files;
            if (files.length <= 0) {
                reject("not chose file.");
            }

            var reader = new FileReader();
            reader.addEventListener("load", () => {
                try {
                    var result: SensorTypeRangeModel = JSON.parse(reader.result + "");

                    if (!result.humidity || !result.ph || !result.temperature ||
                        !result.humidity.max || !result.humidity.min ||
                        !result.ph.max || !result.ph.min ||
                        !result.temperature.max || !result.temperature.min ||
                        isNaN(result.humidity.max) || isNaN(result.humidity.min) ||
                        isNaN(result.ph.max) || isNaN(result.ph.min) ||
                        isNaN(result.temperature.max) || isNaN(result.temperature.min)
                    ) {
                        throw this.error_format_config;
                    }

                    resolve(result);
                } catch {
                    reject(this.error_format_config);
                }
            });
            reader.readAsText(files[0]);
        });
    }



}
import React, { useState } from "react";
import { IonContent, IonPage } from "@ionic/react";
import "./home-container.scss";
import SegmentComponent from "../../components/segment/segment.component";
import HeaderComponent from "../../components/header/header.component";
import SpaceComponent from "../../components/space/space.component";
import HomeFooterComponent from "../../components/home-footer/home-footer.component";
import NotificationCardComponent from "../../components/notification-card/notification-card.component";
import ModalComponent from "../../components/modal/modal.component";
import { useDispatch, useSelector } from "react-redux";
import { globalState, setNotifiation, setShowSearch } from "../../store/slice/global.slice";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import DashboardPage from "./dashboard/dashboard.page";
import HomePage from "./home/home";
import { concreteState } from "../../store/slice/smart-concrete.slice";
import { ConcreteModel } from "../../models/concrete.model";

const HomeContainerPage = () => {
  // const [showModal, setShowModal] = useState(false);
  const global_state = useSelector(globalState);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { concrete_structure } = useSelector(concreteState);

  const footerCallbackAction = (footer_button: string) => {
    if (footer_button === "notifications") {
      // setShowModal(true);
      dispatch(setNotifiation(true));
    } else if (footer_button === "search") {
      dispatch(setShowSearch(true));
    } else {
    }
  };

  // const dismissNotification = () => {
  //   setShowModal(false);
  // }

  const segmentCallback = (tabName: string) => {
    if (tabName === "dashboard") {
      history.push("/home/dashboard");
    } else if (tabName === "home") {
      history.push("/home/structures");
    }
  };

  // useEffect(() => {
  //   return () => {
  //     dispatch(setNotifiation(false))
  //   }
  // }, [])
  // console.log();

  return (
    <IonPage>
      {/* <IonHeader>
        <IonToolbar>
          <IonTitle>Tab 2</IonTitle>
        </IonToolbar>
      </IonHeader> */}
      <IonContent className="page_container" fullscreen>
        {/* <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Tab 2</IonTitle>
          </IonToolbar>
        </IonHeader> */}

        <HeaderComponent
          title_icon="assets/icon/logo.svg"
          title="Smart Concrete"
        />
        <SpaceComponent space={30} />
        <SegmentComponent
          tabs={["Home", "Dashboard"]}
          callback={segmentCallback}
          activeTab={
            location.pathname == "/home/structures" ||
            location.pathname == "/home"
              ? "Home"
              : "Dashboard"
          }
        />
        <SpaceComponent space={10} />

        <Switch>
          <Route path="/home/structures" component={HomePage} />
          <Route path="/home/dashboard" component={DashboardPage} />
          <Redirect exact from="/home" to="/home/structures" />
        </Switch>

        <HomeFooterComponent callback={footerCallbackAction} />

        <ModalComponent
          showModal={global_state ? global_state.notification : false}
          onDidDismiss={() => dispatch(setNotifiation(false))}
          // setShowModal={dispatch(setNotifiation(true))}
          Component={
            concrete_structure &&
            concrete_structure.map((item: ConcreteModel, index: number) => {
              // return concrete_structure.sensors.map((sensore: ConcreteSensorModel) => {
                // callback={dismissNotification}
                return <NotificationCardComponent  key={index} structure={item} />;
              // });
            })
            // <div>
            //   <NotificationCardComponent />
            //   <NotificationCardComponent />
            // </div>
          }
        />
      </IonContent>
    </IonPage>
  );
};

export default HomeContainerPage;

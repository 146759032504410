import { delete_url, insert_url, structure_url, update_config_url, update_url } from './../../core/url';
import axios from "../../core/axios";
import { list_url } from "../../core/url";
import { ResponseModel, SimpleResponseModel } from "../../models/response.model";
import { hasError, setNotifiation, setRange, setSensorModal, setToast, startLoading, stopLoading } from "../slice/global.slice";
import { history } from './../../App';
import { ConcreteModel } from '../../models/concrete.model';
import { AddStructureParamModel } from '../../models/params/add-structure-param.model';
import { selectConcrete, setConcreteList, unsetSelectConcrete, updateSelectConcrete } from '../slice/smart-concrete.slice';
import { StructuresListModel } from '../../models/structures-list.model';
import { SensorTypeRangeModel } from '../../models/sensor-type-range.model';


export function structureListRequest() {
    return async (dispatch: any) => {
        dispatch(unsetSelectConcrete())
        dispatch(startLoading());
        try {
            let response = await axios.get<ResponseModel<StructuresListModel>>(list_url);

            let data = response.data;
            dispatch(setRange(data.result?.config));
            dispatch(setConcreteList(data.result?.structures ?? []));
            dispatch(stopLoading());
        } catch (error) {

            dispatch(stopLoading());
            dispatch(hasError({ message: "error" }))

        }
    };
}


/**
 * add new structure
 * @param params 
 */
export function addStructureRequest(params: AddStructureParamModel) {
    return async (dispatch: any) => {

        dispatch(startLoading());

        try {
            let response = await axios.post<ResponseModel<ConcreteModel>>(insert_url, params);
            let data = response.data;

            dispatch(stopLoading());
            dispatch(setToast({ message: data.message, status: data.status }));
            history.replace("view/general/" + data.result?.id);

        } catch (error) {

            dispatch(stopLoading());
            dispatch(hasError({ message: "error" }))

        }
    };
}



/**
 * delete structure
 * @param params 
 */
export function deleteStructureRequest(id: string) {
    return async (dispatch: any) => {

        dispatch(startLoading());

        try {
            let response = await axios.post<ResponseModel<number>>(delete_url, { id: id });
            let data = response.data;

            dispatch(stopLoading());
            dispatch(setToast({ message: data.message, status: data.status }));
            if (data.status === true) {
                history.goBack();
            }

        } catch (error) {

            dispatch(stopLoading());
            dispatch(hasError({ message: "error" }))

        }
    };
}


/**
 * select structure
 * @param params 
 */
export function getStructureRequest(id: string) {
    return async (dispatch: any) => {

        dispatch(startLoading());

        try {
            let url = structure_url + "?id=" + id;
            let response = await axios.get<ResponseModel<ConcreteModel>>(url);
            let data = response.data;

            if (data.result && data.status === true) {
                dispatch(selectConcrete(data.result));
            }
            dispatch(stopLoading());

        } catch (error) {

            dispatch(stopLoading());
            dispatch(hasError({ message: "error" }))

        }
    };
}


/**
 * update structure
 * @param params 
 */
export function updateStructureRequest(params: ConcreteModel, route?: string) {
    return async (dispatch: any) => {

        dispatch(startLoading());

        try {
            let response = await axios.post<ResponseModel<ConcreteModel>>(update_url, params);
            let data = response.data;

            dispatch(updateSelectConcrete(params));
            dispatch(setToast({ message: data.message, status: data.status }));
            dispatch(stopLoading());
            dispatch(setNotifiation(false));
            dispatch(setSensorModal(false));
            if(route) {
                history.replace(route);
            }
        } catch (error) {

            dispatch(stopLoading());
            dispatch(hasError({ message: "error" }))

        }
    };
}

// config
export function updateConfigRequest(config: SensorTypeRangeModel) {
    return async (dispatch: any) => {

        dispatch(startLoading());

        let param = {
            "config": config
        };

        try {
            let response = await axios.post<SimpleResponseModel>(update_config_url, param);
            let data = response.data;

            dispatch(setToast({ message: data.message, status: data.status }));
            dispatch(stopLoading());
            // dispatch(structureListRequest());

        } catch (error) {

            dispatch(stopLoading());
            dispatch(hasError({ message: "error" }))

        }
    };
}
import {
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCol,
  IonRow,
  IonImg,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { SensorHelper } from "../../helpers/sensor.helper";
import { ConcreteModel } from "../../models/concrete.model";
import { SensorTypeEnum } from "../../models/enums/sensor-type.enum";
import { SensorTypeRangeModel } from "../../models/sensor-type-range.model";
import "./concrete-row.component.scss";

interface ContainerProps {
  model?: ConcreteModel;
  range?: SensorTypeRangeModel;
}

const ConcreteRowComponent: React.FC<ContainerProps> = ({ model, range }) => {
  const [averagePh, setAveragePh] = useState("-");
  const [averageHUM, setAverageHUM] = useState("-");
  const [averageTEMP, setAverageTEMP] = useState("-");
  const [fullyCured, setFullyCured] = useState({ fully: "", day: "" });
  let sensorHelper = new SensorHelper();
  // const {range} = useSelector(globalState);
  // range: SensorTypeRangeModel | undefined;

  useEffect(() => {
    // calculator php average
    let PH = sensorHelper.calc_sensors_average(
      model?.sensors ?? [],
      SensorTypeEnum.PH
    );
    setAveragePh(PH);

    // calculator HUM average
    let HUM = sensorHelper.calc_sensors_average(
      model?.sensors ?? [],
      SensorTypeEnum.HUM
    );
    setAverageHUM(HUM);

    // calculator TEMP average
    let TEMP = sensorHelper.calc_sensors_average(
      model?.sensors ?? [],
      SensorTypeEnum.TEMP
    );
    setAverageTEMP(TEMP);

    let fully_cured = sensorHelper.fully_cured(model?.date ?? "");
    setFullyCured(fully_cured);
  }, [model]);

  const checkNormalRange = (number: string, type: SensorTypeEnum) => {
    if (range) {
      return sensorHelper.checkNormalRangeWithClass(number, type, range);
    }
  };

  return (
    <div className="concrete-row-style">
      <IonCard routerLink={"/view/general/" + model?.id}>
        <IonCardHeader>
          <IonRow>
            <IonCol className="center_vertical" size="2">
              <IonImg src={model?.type?.icon} className="icon" />
            </IonCol>
            <IonCol size="1"></IonCol>
            <IonCol>
              <h3 className="c-black">{model?.name}</h3>
              <h6 className="m-0 p-0 fully-cured-color">{fullyCured.fully}</h6>
            </IonCol>
            {fullyCured.day && fullyCured.day != "" && (
              <IonCol size="2" className="border-left">
                <h3 className="mt-0">{fullyCured.day}</h3>
                <p className="to-fully">to fully cured</p>
              </IonCol>
            )}
          </IonRow>
        </IonCardHeader>

        <IonCardContent>
          <IonRow>
            <ConcreteRowItemComponent
              title={averageTEMP == "-" ? averageTEMP : averageTEMP + "º"}
              subtitle="TEMP"
              src="assets/icon/structures/thermometer.svg"
              className={checkNormalRange(averageTEMP, SensorTypeEnum.TEMP)}
            />
            <ConcreteRowItemComponent
              title={averageHUM == "-" ? averageHUM : averageHUM + "%"}
              subtitle="HUM"
              src="assets/icon/structures/droplet.svg"
              className={checkNormalRange(averageHUM, SensorTypeEnum.HUM)}
            />
            <ConcreteRowItemComponent
              title={averagePh == "-" ? averagePh : averagePh.toString()}
              subtitle="PH"
              src="assets/icon/structures/ph.svg"
              className={checkNormalRange(averagePh, SensorTypeEnum.PH)}
            />
          </IonRow>
        </IonCardContent>
      </IonCard>
    </div>
  );
};

export default ConcreteRowComponent;

export const ConcreteRowItemComponent: React.FC<{
  src: string;
  title: string;
  subtitle: string;
  className?: string;
}> = ({ src, title, subtitle, className }) => {
  return (
    <IonCol className="concrete-card-item">
      <IonRow>
        <IonCol className="image-col">
          <IonImg src={src} className="icon" />
        </IonCol>
        <IonCol size="1"></IonCol>
        <IonCol>
          <p className={"blod " + className}>{title}</p>
          <p className="c-gray s-size blod">{subtitle}</p>
        </IonCol>
      </IonRow>
    </IonCol>
  );
};

import { IonCol, IonImg, IonRow } from "@ionic/react";

import React from "react";
import "./img-title-row.component.scss";

interface ContainerProps {
  onClick?: Function;
  title?: string;
  image_src?: string;
  id: number;
}

const ImgTitleRowComponent: React.FC<ContainerProps> = ({
  onClick,
  title,
  image_src,
  id,
}) => {
  const click = () => {
    if (onClick) {
      onClick(id);
    }
  };

  return (
    <div className="img-title-row-style" onClick={click}>
      <IonRow className="">
        {image_src && (
          <IonCol size="1" style={{ marginRight: 10 }}>
            <IonImg src={image_src} />
          </IonCol>
        )}
        <IonCol className="description pb-10">
          <p>{title}</p>
        </IonCol>

      </IonRow>
    </div>
  );
};

export default ImgTitleRowComponent;

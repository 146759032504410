import { IonAlert, IonCol, IonIcon, IonModal, IonRow } from "@ionic/react";
import {
  informationCircleOutline,
  pencil,
  settings,
  trash,
} from "ionicons/icons";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { JSONHelper } from "../../../helpers/json.helper";
import { SensorHelper } from "../../../helpers/sensor.helper";
import { ConcreteSensorModel } from "../../../models/concrete.model";
import { SensorTypeEnum } from "../../../models/enums/sensor-type.enum";
import { globalState, setToast } from "../../../store/slice/global.slice";
import { concreteState } from "../../../store/slice/smart-concrete.slice";
import { updateStructureRequest } from "../../../store/thunk/smart-concrete.thunk";
import { ConcreteRowItemComponent } from "../../concrete-row/concrete-row.component";
import "./sensors.row.component.scss";

interface ContainerProps {
  model: ConcreteSensorModel;
  index: number;
  onEdit?: Function;
}

const SensorsRowComponent: React.FC<ContainerProps> = ({
  model,
  index,
  onEdit,
}) => {
  const { concrete_structure_selected } = useSelector(concreteState);
  const dispatch = useDispatch();
  const [showAlertInfo, setShowAlertInfo] = useState(false);
  const { range } = useSelector(globalState);
  let sensorHelper = new SensorHelper();

  const info = () => {
    setShowAlertInfo(true);
  };

  const remove = () => {
    let sensors: ConcreteSensorModel[] = [
      ...concrete_structure_selected.sensors,
    ];
    sensors.splice(index, 1);
    let param = {
      ...concrete_structure_selected,
      sensors: sensors,
    };
    dispatch(updateStructureRequest(param));
  };

  const edit = () => {
    if (onEdit) {
      onEdit(index);
    }
  };

  const setting = (e: any) => {
    let jsonHelper = new JSONHelper();
    // reset file
    let files = { ...e.target.files };
    e.target.value = null;

    jsonHelper
      .convertJsonFileToParsJson(files)
      .then((response) => {
        if (response) {
          let sensors: ConcreteSensorModel[] = [
            ...concrete_structure_selected.sensors,
          ];
          sensors[index] = { ...sensors[index], data: response };
          let param = {
            ...concrete_structure_selected,
            sensors: sensors,
          };
          dispatch(updateStructureRequest(param));
        }
      })
      .catch((e) => {
        dispatch(setToast({ message: e, status: false }));
      });
  };

  // const checkNormalRange = (number: number[], type: SensorTypeEnum) => {
  //   if (range) {
  //     // return sensorHelper.checkNormalRange([...number].pop(), type, range);
  //   }
  // };

  const checkNormalRange = () => {
    
    if(model.data?.length == 0) {
      return "not data";
      return;
    }
    
    if (range && model.data) {

      
      let average = [...model.data].pop() + "";
      // console.log(average);
      // console.log(model);
      // console.log(range);
      // console.log('====================================');

      let status = sensorHelper.checkNormalRangeWithClass(
        average,
        model.type as SensorTypeEnum,
        range
      );
      if (status === "") return <span className="c-gray">{model.type} is normal</span>;;

      if (status === "high") {
        return <span className="high">{model.type} is too high</span>;
      } else {
        return <span className="low">{model.type} is low</span>;
      }
    }

    return undefined;
  };

  return (
    <IonRow className="sensors-row-style flex">
      <IonCol size="1">{index + 1}</IonCol>
      <IonCol size="3" className="nowrap">
        {model.name}
      </IonCol>
      <IonCol size="3" className="nowrap">
        {model.description}
      </IonCol>
      <IonCol size="1" className="nowrap">
        {model.class}
      </IonCol>
      <IonCol size="4">
        <IonRow className="flex">
          <IonCol className="icon text-center info cursor" onClick={info}>
            <IonIcon icon={informationCircleOutline} />

            <IonModal
              isOpen={showAlertInfo}
              onDidDismiss={() => setShowAlertInfo(false)}
              cssClass="info-modal"
            >
              <div className="container-info">
                <IonRow>
                  <ConcreteRowItemComponent
                    title={
                      model.data && model.data.length > 0
                        ? [...model.data].pop() + ""
                        : "-"
                    }
                    subtitle={model.type ?? "-"}
                    src={
                      "assets/icon/structures/" +
                      (model.type == "TEMP"
                        ? "thermometer.svg"
                        : model.type == "PH"
                        ? "ph.svg"
                        : "droplet.svg")
                    }
                    // className={checkNormalRange(model.data ?? [], SensorTypeEnum.PH)}
                  />
                </IonRow>
                <span className="text-center">{checkNormalRange()}</span>
              </div>
            </IonModal>

            {/* <IonAlert
              isOpen={showAlertInfo}
              onDidDismiss={() => setShowAlertInfo(false)}
              header={"The latest available data"}
              message={
                model.data && model.data.length > 0
                  ? [...model.data].pop() + ""
                  : "There is no data."
              }
              buttons={["OK"]}
            /> */}
          </IonCol>
          <IonCol className="icon text-center trash cursor" onClick={remove}>
            <IonIcon icon={trash} />
          </IonCol>
          <IonCol className="icon text-center edit cursor" onClick={edit}>
            <IonIcon icon={pencil} />
          </IonCol>
          <IonCol className="icon text-center setting cursor">
            <label>
              <input
                id="choseFile"
                type="file"
                onChange={setting}
                accept=".json"
                style={{ display: "none" }}
              />

              <IonIcon icon={settings} />
            </label>
          </IonCol>
        </IonRow>
      </IonCol>
    </IonRow>
  );
};

export default SensorsRowComponent;

import React, { useEffect, useRef, useState } from "react";
import "./dashboard.page.scss";
import SpaceComponent from "../../../components/space/space.component";
import { useDispatch, useSelector } from "react-redux";
import { globalState, setNotifiation } from "../../../store/slice/global.slice";
import ChartTestComponent from "../../../components/chart/chart-test.component";
import { StatusCardComponent } from "../../../components/card/status-card.component";
import {
  IonButton,
  IonCol,
  IonContent,
  IonIcon,
  IonRow,
  IonSlide,
  IonSlides,
} from "@ionic/react";
import ChartBarComponent from "../../../components/chart/bar/chart-bar.component";
import { ChartBarModel } from "../../../components/chart/bar/chart-bar.model";
import PopoverComponent from "../../../components/popover/popover.component";
import { chevronBack, chevronForward } from "ionicons/icons";
import {
  data2DailyChartBar,
  data2MonthlyChartBar,
  dataDailyChartBar,
  dataMonthlyChartBar,
  dataMonthlyChartPie,
  dataWeeklyChartPie,
} from "./dashboard.data";
import ChartPieComponent from "../../../components/chart/pie/pie-bar.component";
import { ChartPieDataModel } from "../../../components/chart/pie/pie-bar.model";
import { SensorHelper } from "../../../helpers/sensor.helper";
import { concreteState } from "../../../store/slice/smart-concrete.slice";
import { SensorTypeEnum } from "../../../models/enums/sensor-type.enum";
import { structureListRequest } from "../../../store/thunk/smart-concrete.thunk";

const DashboardPage = () => {
  const global_state = useSelector(globalState);
  const { concrete_structure } = useSelector(concreteState);
  const dispatch = useDispatch();

  const [dataBar, setDataBar] = useState<ChartBarModel>();
  const [filter, setFilter] = useState("Weekly");
  const [index, setIndex] = useState(0);

  const [dataPie, setDataPie] = useState<ChartBarModel>();
  const [filterPie, setFilterPie] = useState("Weekly");
  const [indexPie, setIndexPie] = useState(0);

  const [numberOfCard, setNumberOfCard] = useState<{
    ph: number;
    hum: number;
    fully: number;
  }>({
    ph: 0,
    hum: 0,
    fully: 0,
  });

  let sensorHelper = new SensorHelper();

  const slidesEl = useRef(document.createElement("ion-slides"));

  // slide: React.MutableRefObject<HTMLIonSlidesElement>
  const next = () => {
    if (index == 1) {
      return;
    }
    averageChart(SensorTypeEnum.TEMP, "Avarage of Temperature");
    setIndex(1);
  };
  const prev = () => {
    if (index == 0) {
      return;
    }
    averageChart(SensorTypeEnum.PH);
    setIndex(0);
  };

  const popoverBarChart = (filter: string) => {
    setFilter(filter);
  };

  // useEffect(() => {
  //   selectData(index, filter);
  // }, [index, filter]);

  // const selectData = (index: number = 0, filter: string) => {
  //   if (index == 0) {
  //     if (filter === "daily") {
  //       setDataBar(dataDailyChartBar);
  //     } else if (filter === "monthly") {
  //       setDataBar(dataMonthlyChartBar);
  //     }
  //   } else {
  //     if (filter === "daily") {
  //       setDataBar(data2DailyChartBar);
  //     } else if (filter === "monthly") {
  //       setDataBar(data2MonthlyChartBar);
  //     }
  //   }
  // };

  useEffect(() => {
    if (!concrete_structure || concrete_structure.length == 0) {
      dispatch(structureListRequest());
    }
    // selectDataPie(indexPie, filterPie);
  }, [indexPie, filterPie]);

  useEffect(() => {
    if (global_state.range && concrete_structure) {
      let ph = sensorHelper.countOfStructursHigh(
        concrete_structure,
        SensorTypeEnum.PH,
        global_state.range
      );
      let hum = sensorHelper.countOfStructursHigh(
        concrete_structure,
        SensorTypeEnum.HUM,
        global_state.range
      );
      let fully_cured = sensorHelper.countOfStructursfully_cured(
        concrete_structure
      );

      averageChart(SensorTypeEnum.PH);

      setNumberOfCard({
        ph: ph,
        hum: hum,
        fully: fully_cured,
      });

      let length = concrete_structure.length;
      let TEMP = sensorHelper.countOfStructursHigh(
        concrete_structure,
        SensorTypeEnum.TEMP,
        global_state.range
      );

      // today
      // var date = new Date();
      // var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

      var d = new Date();
      d.setDate(d.getDate() - 5);
      var mS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
      let now = new Date();
      let pie = dataWeeklyChartPie(
        d.getDate() + " to "+ now.getDate() +" " + mS[now.getMonth()],
        Math.round((ph / length) * 100),
        Math.round((hum / length) * 100),
        Math.round((TEMP / length) * 100)
      );
      setDataPie(pie);
    }
  }, [concrete_structure]);

  const averageChart = (
    type: SensorTypeEnum,
    title: string = "Avarage of PH"
  ) => {
    let average = sensorHelper.avergeAllSensorData(concrete_structure, type);
    let averageChart = sensorHelper.generateChartData(average);
    setDataBar({
      name: title,
      subCaption: "",
      yAxisMaxValue: Math.max(...average),
      data: averageChart.data,
    });
  };

  const popoverPieChart = (filter: string) => {
    setFilterPie(filter);
  };

  const nextPie = () => {
    if (indexPie == 1) {
      return;
    }
    setIndexPie(1);
  };
  const prevPie = () => {
    if (indexPie == 0) {
      return;
    }
    setIndexPie(0);
  };

  // const selectDataPie = (index: number = 0, filter: string) => {
  //   if (index == 0 && filter == "weekly") {
  //     // if (filterPie === "daily") {
  //     setDataPie(dataMonthlyChartPie);
  //     // } else if (filter === "monthly") {
  //     //   setDataBar(dataMonthlyChartBar);
  //     // }
  //   } else {
  //     setDataPie(dataWeeklyChartPie);
  //   }
  // };

  return (
    <>
      <SpaceComponent space={20} />
      <div className="support_desktop">
        <div className="flex j-between full suppot-400">
          <StatusCardComponent
            number_title={numberOfCard?.ph + ""}
            number_subtitle="number of too high"
            title="PH level"
            subtitle="concrete structures"
            gradient_one="rgba(255, 230, 239, 0)"
            gradient_two="#ffdee8"
            color="#ed135b"
            s_color="#ff91b5"
          />
          <StatusCardComponent
            number_title={numberOfCard?.hum + ""}
            number_subtitle="number of too high"
            title="Humidity level"
            subtitle="concrete structures"
            gradient_one="rgba(230, 240, 255, 0) 4%"
            gradient_two="#cce1ff"
            color="#4791ff"
            s_color="#4791ff"
          />
          <StatusCardComponent
            number_title={numberOfCard?.fully + ""}
            number_subtitle="number of"
            title="Fully cured"
            subtitle="concrete structures"
            gradient_one="rgba(223, 247, 238, 0)"
            gradient_two="#caf0e3"
            color="#02bc77"
            s_color="#02bc77"
          />
        </div>

        <SpaceComponent space={20} />

        {dataBar && (
          <div>
            <IonRow>
              <IonCol>
                <IonIcon onClick={() => prev()} icon={chevronBack} />
                {" " + dataBar.name + " "}
                <IonIcon onClick={() => next()} icon={chevronForward} />
              </IonCol>
              <IonCol className="text-right not-allowed">
                <PopoverComponent
                  not_allowed={true}
                  callback={popoverBarChart}
                  title={filter}
                  list={["weekly"]}
                />
              </IonCol>
            </IonRow>
            <p className="ml-20 s-size">
              of all structures that have not fully cured yet
            </p>
            <SpaceComponent space={10} />
            {/* <IonSlides ref={slidesEl}>
          <IonSlide> */}

            <ChartBarComponent model={dataBar} />

            {/* </IonSlide> */}

            {/* <IonSlide>
            <ChartBarComponent model={data2MonthlyChartBar} />
          </IonSlide>
        </IonSlides> */}
          </div>
        )}
        <SpaceComponent space={40} />
        {dataPie && (
          <div className="pieChart">
            <div className="deg90">
              <IonIcon onClick={() => prevPie()} icon={chevronBack} />
              {" " + dataPie.name + " "}
              <IonIcon onClick={() => nextPie()} icon={chevronForward} />
            </div>
            <IonRow>
              <IonCol>
                <h3 className="ml-10 pie_title m-0">
                  Concrete structures status
                </h3>
              </IonCol>
              <IonCol size="3" className="text-right not-allowed">
                <PopoverComponent
                  not_allowed={true}
                  callback={popoverPieChart}
                  title={filterPie}
                  list={["weekly"]}
                />
              </IonCol>
            </IonRow>
            <SpaceComponent space={10} />
            <ChartPieComponent model={dataPie} />
          </div>
        )}

        <SpaceComponent space={40} />
      </div>

      {/* <ChartBarComponent data={dataChartBar} /> */}

      <SpaceComponent space={100} />
    </>
  );
};

export default DashboardPage;

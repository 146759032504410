import React, { useEffect, useState } from "react";
import { IonContent, IonIcon, IonPage, IonPopover } from "@ionic/react";

import SegmentComponent from "../../components/segment/segment.component";
import HeaderComponent from "../../components/header/header.component";
import SpaceComponent from "../../components/space/space.component";
import HomeFooterComponent from "../../components/home-footer/home-footer.component";
import NotificationCardComponent from "../../components/notification-card/notification-card.component";
import ModalComponent from "../../components/modal/modal.component";
import { useDispatch, useSelector } from "react-redux";
import { globalState, setNotifiation } from "../../store/slice/global.slice";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import ViewStructurePage from "./view-structure/view-structure";
import StatisticalPage from "./statistical/statistical.page";
import { ellipsisVerticalSharp, trash } from "ionicons/icons";
import { concreteState } from "../../store/slice/smart-concrete.slice";
import { deleteStructureRequest } from "../../store/thunk/smart-concrete.thunk";

const ViewStructureContainerPage = () => {
  const global_state = useSelector(globalState);
  const history = useHistory();
  const location = useLocation();
  const params = useParams();

  const concrete_state = useSelector(concreteState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setNotifiation(false));
  }, []);

  const segmentCallback = (tabName: string) => {
    // if (tabName === "general") {
    //   history.push("/view/general");
    // } else if (tabName === "statistical") {
    //   history.push("/view/statistical");
    // }
    // alert("change");
    // console.log("====================================");
    // console.log(tabName);
    // console.log("====================================");
    let route = "";
    if (tabName == "statistical") {
      route = "/view/statistical/";
    } else {
      route = "/view/general/";
    }
    // let route = !location.pathname.includes("statistical")
    //   ? "/view/statistical/"
    //   : "/view/general/";

    if (!concrete_state.concrete_structure_selected.id) return;

    route += concrete_state.concrete_structure_selected.id;
    history.replace(route);
  };

  // handle right icon
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });
  const rightMenuAction = () => {
    // alert(1);
    setShowPopover({ showPopover: true, event: undefined });
  };

  const deleteStructure = () => {
    setShowPopover({ showPopover: false, event: undefined });
    if (concrete_state.concrete_structure_selected) {
      dispatch(
        deleteStructureRequest(concrete_state.concrete_structure_selected.id)
      );
    }
  };

  // console.log();

  return (
    <IonPage>
      <IonContent className="page_container" fullscreen>
        {concrete_state && concrete_state.concrete_structure_selected && (
          <HeaderComponent
            title_icon={concrete_state.concrete_structure_selected.type.icon}
            title={
              concrete_state.concrete_structure_selected.name
            }
            isBack={true}
            RightComponent={
              <>
                <IonIcon
                  icon={ellipsisVerticalSharp}
                  onClick={rightMenuAction}
                  size="large"
                  className="cursor"
                />
                <IonPopover
                  cssClass="ion-popover-delete"
                  event={popoverState.event}
                  isOpen={popoverState.showPopover}
                  onDidDismiss={() =>
                    setShowPopover({ showPopover: false, event: undefined })
                  }
                >
                  <p onClick={deleteStructure} className="p-20 cursor">
                    <IonIcon icon={trash} className="mr-10" />
                    Delete
                  </p>
                </IonPopover>
              </>
            }
          />
        )}

        <SpaceComponent space={30} />
        <SegmentComponent
          tabs={["General", "Statistical"]}
          callback={segmentCallback}
          activeTab={
            !location.pathname.includes("statistical")
              ? "General"
              : "Statistical"
          }
        />

        <Switch>
          <Route path="/view/general/:id" component={ViewStructurePage} />
          <Route path="/view/statistical/:id" component={StatisticalPage} />
          <Redirect exact from="/view" to="/view/general" />
        </Switch>
      </IonContent>
    </IonPage>
  );
};

export default ViewStructureContainerPage;

import React, { useEffect, useState } from "react";
import { IonRow, IonCol, IonIcon, IonGrid } from "@ionic/react";
import "./statistical.page.scss";
import SpaceComponent from "../../../components/space/space.component";
import SplineBarComponent from "../../../components/chart/spline/spline-bar.component";
import { SensorHelper } from "../../../helpers/sensor.helper";
import { concreteState } from "../../../store/slice/smart-concrete.slice";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../../App";
import { getStructureRequest } from "../../../store/thunk/smart-concrete.thunk";
import PopoverComponent from "../../../components/popover/popover.component";
import { SensorTypeEnum } from "../../../models/enums/sensor-type.enum";
import { ChartBarModel } from "../../../components/chart/bar/chart-bar.model";
import MssplineComponent, {
  CategoriesChartModel,
} from "../../../components/chart/msspline/msspline.component";
import { ConcreteSensorModel } from "../../../models/concrete.model";
import { calculator, chevronDown } from "ionicons/icons";
import { ChartStatustucalModel } from "../../../models/chart-statustucal.model";
import LineBarSensorComponent from "../../../components/line-bar-sensor/line-bar-sensor.component";

const StatisticalPage = () => {

  const [indexTEMP, setIndexTEMP] = useState<number>(0);
  const [indexHUM, setIndexHUM] = useState<number>(0);
  const [indexPH, setIndexPH] = useState<number>(0);


  const [fullyCured, setFullyCured] = useState({ fully: "", day: "" });
  const [threeSensorsInformation, setThreeSensorsInformation] = useState<
    CategoriesChartModel[]
  >([]);

  const params = useParams<{ id: string }>();

  const { concrete_structure_selected } = useSelector(concreteState);
  // const global_state = useSelector(globalState);
  const dispatch = useDispatch();

  let sensorHelper = new SensorHelper();

  useEffect(() => {
    const structureRequest = () => {
      if (!params || !params.id) {
        dispatch(history.goBack());
        return;
      }
      dispatch(getStructureRequest(params.id));
    };
    structureRequest();
  }, []);


  useEffect(() => {
    if (concrete_structure_selected) {
      calculator();
    }
  }, [indexTEMP, indexPH, indexHUM]);

  useEffect(() => {
    if (concrete_structure_selected) {
      let fully_cured = sensorHelper.fully_cured(
        concrete_structure_selected.date ?? ""
      );
      setFullyCured(fully_cured);

      calculator();
    }
  }, [concrete_structure_selected]);

  const calculator = () => {

    let dataCategoriesChartModel: CategoriesChartModel[] = [];

    let temperature_chart = calcChartData(SensorTypeEnum.TEMP, indexTEMP);
    if (temperature_chart && temperature_chart.data.length > 0) {
      dataCategoriesChartModel.push(temperature_chart);
    }

    let humidity_chart = calcChartData(SensorTypeEnum.HUM, indexHUM);
    if (humidity_chart && humidity_chart.data.length > 0) {
      dataCategoriesChartModel.push(humidity_chart);
    }

    let ph_chart = calcChartData(SensorTypeEnum.PH, indexPH);
    if (ph_chart && ph_chart.data.length > 0) {
      dataCategoriesChartModel.push(ph_chart);
    }

    setThreeSensorsInformation(dataCategoriesChartModel);
  };

  const calcChartData = (type: SensorTypeEnum, index: number) : CategoriesChartModel | undefined => {
    let ph_chart = getDataSensoreForChart(type);

    if (ph_chart.numberOfSensor > 0) {
      let ph_chart_model = sensorHelper.generateChartWithSensorData(
        ph_chart.sensor[index]
      );
      return {
        seriesname: ph_chart_model.name,
        data: ph_chart_model.data,
      }
    }
    return undefined;
  }

  const getDataSensoreForChart = (type: SensorTypeEnum) => {
    let chart = sensorHelper.getDataSensoreForChart(
      concrete_structure_selected.sensors ?? [],
      type,
      0
    );
    return chart;
  };

  return (
    <>
      <div className="support_desktop statisticalPage">
        <SpaceComponent space={20} />
        {/* {fullyCured.day && ( */}
        <>
          <IonGrid className="mb-20">
            <IonRow>
              <IonCol className="fullyCured-day" size="4">
                <h2>{fullyCured.day !== "" ? fullyCured.day : "0"}</h2>
              </IonCol>
              <IonCol size="8">
                {/* {fullyCured.day !== "" && <p>days remaining to</p> } */}
                <p>days remaining to</p>
                <h3 className="m-0 p-0">completely cured</h3>
                <div className="slider_fullyCured">
                  {Array.from(Array(28).keys()).map((item, index) => {
                    return 28 - Number(fullyCured.day) > index ? (
                      <span key={index} className="fully_cuerd green"></span>
                    ) : (
                      <span key={index} className="fully_cuerd"></span>
                    );
                  })}
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
          <SpaceComponent space={20} />
        </>
        {/* )} */}

        <IonGrid>
          <IonRow>
            <LineBarSensorComponent
              type={SensorTypeEnum.TEMP}
              palettecolors="#ffce17"
              name="Temperature"
              callback={(index: number) => {
                setIndexTEMP(index);
              }}
            />
            <LineBarSensorComponent
              type={SensorTypeEnum.HUM}
              palettecolors="#4791ff"
              name="Humidity"
              callback={(index: number) => {
                setIndexHUM(index);
              }}
            />
            <LineBarSensorComponent
              type={SensorTypeEnum.PH}
              palettecolors="#ed135b"
              name="PH"
              callback={(index: number) => {
                setIndexPH(index);
              }}
            />

            {/* {chartData &&
              chartData.map((item, index) => {
                return (
                  <IonCol size="6" key={index} className="chart">
                    <IonRow>
                      <IonCol></IonCol>
                      <IonCol size="8" className="text-right">
                        <span>
                          sensor:
                          <PopoverComponent
                          callback={(name: string) => {
                            const newName = name.replace("#", "");  
                            let filter = item.sensors!.filter(f => f.name == newName);
                              console.log('====================================');
                              console.log(filter);
                              
                              console.log(name);
                              console.log('====================================');
                          }}
                            title={item.sensor?.name}
                            not_allowed={false}
                            list={item.sensors ? item.sensors?.map((item) => "#" + (item.name)) : []}
                          />
                        </span>
                      </IonCol>
                    </IonRow>
                    <SplineBarComponent
                      model={item?.model}
                      palettecolors={item?.palettecolors}
                    />
                    <p className="placeholder">{item.name}</p>
                  </IonCol>
                );
              })} */}

            <IonCol size="6" className="chart">
              <MssplineComponent model={threeSensorsInformation} />
              <p className="placeholder">
                All {threeSensorsInformation.length} sensors information
              </p>
              {/* <SplineBarComponent /> */}
              {/* Humidity */}
            </IonCol>
          </IonRow>
          {/* <IonRow>
            <IonCol size="6">
              <SplineBarComponent />
            </IonCol>
            <IonCol size="6">
              <SplineBarComponent />
            </IonCol>
          </IonRow> */}
        </IonGrid>

        {/* <SplineBarComponent /> */}

        {/* <ChartPieComponent model={dataWeeklyChartPie} /> */}

        {/* <ChartBarComponent model={dataWeeklyChartPie} /> */}

        <SpaceComponent space={40} />

        <IonRow className="">
          <IonCol className="text-left">
            <p className="mb-10">Live information of all sensors</p>
          </IonCol>
          <IonCol size="4" className="text-right">
            <button className="refresh">refresh</button>
          </IonCol>
        </IonRow>

        <SpaceComponent space={10} />
        <IonRow className="header">
          <IonCol size="1">#</IonCol>
          <IonCol size="5">
            Sensor name <IonIcon className="" icon={chevronDown} />
          </IonCol>
          <IonCol size="3" className="text-center">
            Type <IonIcon className="" icon={chevronDown} />
          </IonCol>
          <IonCol size="3" className="text-center">
            Value <IonIcon className="" icon={chevronDown} />
          </IonCol>
        </IonRow>
        {/* <SpaceComponent space={10} /> */}
        <div className="line" style={{ height: 1 }}></div>
        <SpaceComponent space={10} />

        {concrete_structure_selected &&
          concrete_structure_selected.sensors &&
          concrete_structure_selected.sensors.map(
            (item: ConcreteSensorModel, index: number) => {
              return (
                <IonRow className="row_sensor" key={index}>
                  <IonCol size="1">
                    <p>{index + 1}</p>
                  </IonCol>
                  <IonCol size="5">{item.name}</IonCol>
                  <IonCol size="3" className="text-center">
                    {item.class}
                  </IonCol>
                  {item.data && (
                    <IonCol size="3" className="text-center">
                      {item.data[item.data?.length - 1]}
                      {item.type === SensorTypeEnum.TEMP
                        ? "º"
                        : item.type === SensorTypeEnum.HUM
                        ? "%"
                        : ""}
                    </IonCol>
                  )}
                </IonRow>
              );
            }
          )}

        <SpaceComponent space={20} />
      </div>
    </>
  );
};

export default StatisticalPage;

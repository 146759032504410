import React, { useEffect, useState } from "react";
import { IonContent, IonPage, IonRow, IonCol } from "@ionic/react";
import "./add-new-structure.scss";
import HeaderComponent from "../../components/header/header.component";
import SpaceComponent from "../../components/space/space.component";
import SelectComponent from "../../components/forms/select/select.component";
import DateComponent from "../../components/forms/date/date.component";
import InputAreaComponent from "../../components/forms/text-area/input-textarea.component";
import MapComponent from "../../components/map/map.component";
import { useDispatch, useSelector } from "react-redux";
import { concreteStructureTypeList } from "../../store/thunk/concrete-structure-type.thunk";
import {
  globalState,
  setNotifiation,
  setToast,
} from "../../store/slice/global.slice";
import { SelectModel } from "../../components/forms/select/select.model";
import { ConcreteStructureTypeModel } from "../../models/concrete-structure.model";
import ModalComponent from "../../components/modal/modal.component";
import ImgTitleRowComponent from "../../components/forms/img-title-row/img-title-row.component";
import {
  addStructureRequest,
} from "../../store/thunk/smart-concrete.thunk";
import InputTextComponent from "../../components/forms/input-text/input-text.component";
import { AddStructureParamModel } from "../../models/params/add-structure-param.model";
import { concreteState } from "../../store/slice/smart-concrete.slice";

enum AddNewStructureEnum {
  name,
  // type,
  date,
  description,
  info,
}

const AddNewStructurePage = () => {
  const dispatch = useDispatch();
  const global_state = useSelector(globalState);
  const concrete_state = useSelector(concreteState);


  const [name, setName] = useState("");
  const [typeStructure, setTypeStructure] = useState<SelectModel>();
  const [date, setDate] = useState("");
  const [description, setDescription] = useState("");
  const [info, setInfo] = useState("");

  const onChange = (text: string, type: AddNewStructureEnum) => {
    switch (type) {
      case AddNewStructureEnum.name:
        setName(text);
        break;
      case AddNewStructureEnum.description:
        setDescription(text);
        break;
      case AddNewStructureEnum.info:
        setInfo(text);
        break;
      case AddNewStructureEnum.date:
        setDate(text);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(concreteStructureTypeList());
  }, []);

  const addStructure = () => {
    if (!name) {
      dispatch(
        setToast({ message: "Please enter the name of concrete structure." })
      );
      return;
    }
    if (!typeStructure) {
      dispatch(
        setToast({ message: "Please enter the type of concrete structure." })
      );
      return;
    }
    if (!date) {
      dispatch(setToast({ message: "Please enter the date of concreting." }));
      return;
    }
    if (!description) {
      dispatch(setToast({ message: "Please enter the description field." }));
      return;
    }

    let param: AddStructureParamModel = {
      name: name,
      date: date,
      description: description,
      info: info,
      type: typeStructure,
      date_create: Date.now()
    };
    dispatch(addStructureRequest(param));
  };

  const reset = () => {
    setName("");
    setTypeStructure(undefined);
    setDate("");
    setDescription("");
    setInfo("");
  };

  const structureSelect = (item: ConcreteStructureTypeModel) => {
    setTypeStructure({
      icon: item.icon,
      title: item.title ?? "",
      id: item.id ?? 0,
    });
    dispatch(setNotifiation(false));
  };

  return (
    <IonPage>
      <IonContent className="page_container" fullscreen>
        <HeaderComponent title="Adding A New Structure" isBack={true} />
        <SpaceComponent space={40} />
        <div className="support_desktop">
          <InputTextComponent
            value={name}
            onEndedChange={(text: string) =>
              onChange(text, AddNewStructureEnum.name)
            }
          />

          <SpaceComponent space={20} />

          <SelectComponent value={typeStructure} />

          <ModalComponent
            showModal={global_state ? global_state.notification : false}
            onDidDismiss={() => dispatch(setNotifiation(false))}
            Component={
              concrete_state.concrete_structure_data &&
              concrete_state.concrete_structure_data.structure_type &&
              concrete_state.concrete_structure_data.structure_type.map(
                (item: ConcreteStructureTypeModel, index: number) => {
                  return (
                    <ImgTitleRowComponent
                      id={index}
                      title={item.title}
                      image_src={item.icon}
                      onClick={() => structureSelect(item)}
                      key={index}
                    />
                  );
                }
              )
            }
          />

          <SpaceComponent space={20} />

          <DateComponent
            title="Date of concreting"
            value={date}
            onEndedChange={(date: string) =>
              onChange(date, AddNewStructureEnum.date)
            }
          />

          <SpaceComponent space={20} />
          <InputAreaComponent
            value={description}
            title="Description"
            onEndedChange={(date: string) =>
              onChange(date, AddNewStructureEnum.description)
            }
          />

          <SpaceComponent space={20} />

          <MapComponent />

          <SpaceComponent space={20} />
          <InputAreaComponent
            value={info}
            title="Other necessary info"
            isOptional={true}
            onEndedChange={(date: string) =>
              onChange(date, AddNewStructureEnum.info)
            }
          />
          <SpaceComponent space={20} />

          <div className="panel-add fixed">
            <IonRow className="content">
              <IonCol size="5">
                <div className="button-reset" onClick={reset}>
                  Reset
                </div>
              </IonCol>
              <IonCol>
                <div className="button-black" onClick={addStructure}>
                  Add
                </div>
              </IonCol>
            </IonRow>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default AddNewStructurePage;

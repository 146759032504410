import React from "react";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import ReactFC from "react-fusioncharts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import "./pie-bar.component.scss";
import { ChartBarModel } from "../bar/chart-bar.model";

// import { ChartPieModel } from "./pie-bar.model";

// https://www.fusioncharts.com/dev/chart-attributes/column2d
ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

interface ChartBarComponentProps {
  model: ChartBarModel;
}

const ChartPieComponent: React.FC<ChartBarComponentProps> = ({ model }) => {
  const chartConfigs = {
    type: "doughnut2d",
    width: "100%",
    height: 300,
    dataFormat: "json",
    dataSource: {
      chart: {
        // caption: "Split of Visitors by Age Group",
        // subCaption: "Last year",
        use3DLighting: false,
        showPercentValues: false,
        decimals: false,
        useDataPlotColorForLabels: false,
        theme: "fusion",
        // defaultCenterLabel: "24%",
        showLegend: false,
        pieRadius: 100,
        doughnutRadius: 65,
        enableMultiSlicing: false,
        defaultCenterLabel:
          "<strong>" + (model.initialValue ?? 0) + "%</strong><br>too high PH",
        centerLabel: "<strong>$value%</strong><br>$label",
        // valuePosition: "inside",
        minAngleForValue: "75",
        enablesmartLabel: false,
        startingAngle: 0,

        labelFontColor: "#fafafa",
        // bgColor: "#fafafa",
        showTooltip: false,
        // baseFontSize: 40,
        // outCnvBaseFontSize: 40,
        // labelFontSize: 40,
        // chartLeftMargin: 10,

        // palettecolors: "ed135b, ebebeb, ebebeb, ebebeb",
        // usePlotGradientColor: "1",
        // plotGradientColor: "ed135b",

        // "labelBorderPadding": "5",
        // "labelBorderRadius": "2",
        // "labelBorderDashed": "1",
        // "labelBorderDashGap": "2",
        // "labelBorderDashLen": "3",
        // "labelBorderThickness": "2",
        // "labelBorderColor": "#00ffaa",
        // "labelBorderAlpha": "60"

        // labelFontColor: "red"
        // caption: "",
        // subCaption: model.subCaption ?? "",
        // xAxisName: "",
        // yAxisName: "",
        // numberSuffix: "",
        // palettecolors: "ed135b, ebebeb",
        // theme: "fusion",
        // usePlotGradientColor: "1",
        // plotGradientColor: "ed135b",
        // bgColor: "#fafafa",
        // maxColWidth: 20,
        // yAxisMaxValue: model.yAxisMaxValue ?? 100,
      },
      data: model.data,
      // [
      //   {
      //     label: "too high PH",
      //     value: 35,
      //     isSliced: 1,
      //     // labelBgColor: "#0000ff"
      //   },
      //   {
      //     label: "too high PH2",
      //     value: 87,
      //   },
      //   {
      //     label: "too high PH3",
      //     value: 52,
      //   },
      //   // {
      //   //   label: "too high PH4",
      //   //   value: 9,
      //   // },
      // ],
    },
    // events: {
    //   slicingStart: function (evtObj: any, argObj: any) {
    //     // console.log('==================================== argObj');
    //     // console.log(argObj);
    //     // console.log('====================================');
    //     var data = argObj.data,
    //       catLabel = data && data.categoryLabel,
    //       slicedState = argObj.slicedState,
    //       targetColor = !slicedState ? "#6baa01" : "#ffffff",
    //       fontColor = !slicedState ? "#ffffff" : "#6baa01",
    //       targetTdIndx;

    //       model.data[0].color = targetColor;

    //     console.log(!slicedState);
    //     console.log(evtObj);

    //     // changeState(catLabel, targetColor, fontColor);
    //   },
    // },
  };

  return (
    <div className="chart-pie-component">
      <svg
        className="width0"
        // style={{width:0,height:0,position:absolute}}"
        aria-hidden="true"
        focusable="false"
      >
        <linearGradient id="chart-pie-gradient" x2="1" y2="1">
          <stop offset="0%" stopColor="#ed135b" />
          <stop offset="100%" stopColor="#ff5d93" />
        </linearGradient>
      </svg>
      <ReactFC {...chartConfigs} />
    </div>
  );
};

export default ChartPieComponent;

import { IonToast } from "@ionic/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { globalState, resetToast } from "../../store/slice/global.slice";
// import "./modal.component.scss";

interface ContainerProps {
  //   showModal: boolean;
  //   onDidDismiss?: Function;
  //   Component: JSX.Element;
}

const ToastComponent: React.FC<ContainerProps> = () => {
  const global_state = useSelector(globalState);
  const dispatch = useDispatch();
  // const history = useHistory();

  const dismissAction = () => {
    dispatch(resetToast());
  };

  return (
    <IonToast
      isOpen={global_state.toast !== undefined}
      onDidDismiss={() => dismissAction()}
      message={
        global_state.toast !== undefined ? global_state.toast.message : ""
      }
      position="bottom"
      color={
        global_state.toast && global_state.toast.status === true
          ? "success"
          : "danger"
      }
      duration={3500}
      buttons={[
        {
          text: "close",
          role: "cancel",
          handler: () => {
            dismissAction();
          },
        },
      ]}
    />
  );
};

export default ToastComponent;

import { IonCol, IonRow } from "@ionic/react";
import { calculator } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SensorHelper } from "../../helpers/sensor.helper";
import { ChartStatustucalModel } from "../../models/chart-statustucal.model";
import { SensorTypeEnum } from "../../models/enums/sensor-type.enum";
import { concreteState } from "../../store/slice/smart-concrete.slice";
import SplineBarComponent from "../chart/spline/spline-bar.component";
import PopoverComponent from "../popover/popover.component";

type LineBarSensorComponentType = {
    type: SensorTypeEnum;
    name: string;
    palettecolors: string;
    callback?: (index: number) => void;
};

const LineBarSensorComponent = (props: LineBarSensorComponentType) => {

  const [chartData, setChartData] = useState<ChartStatustucalModel>();
  const { concrete_structure_selected } = useSelector(concreteState);

  let sensorHelper = new SensorHelper();

  useEffect(() => {
    if (concrete_structure_selected) {
      calculator();
    }
  }, [concrete_structure_selected]);

  const calculator = (index: number = 0) => {
    let temperature_chart = getDataSensoreForChart(props.type);

    if (temperature_chart.numberOfSensor > 0) {
      let temperature_chart_model = sensorHelper.generateChartWithSensorData(
        temperature_chart.sensor[index]
      );

      setChartData({
        sensor: temperature_chart.sensor[index],
        sensors: temperature_chart.sensor,
        numberOfSensor: temperature_chart.numberOfSensor,
        model: temperature_chart_model,
        palettecolors: props.palettecolors,
        name: props.name,
      });
    }
    if(props.callback) {
        props.callback(index);
    }
  };

  const getDataSensoreForChart = (type: SensorTypeEnum) => {
    let chart = sensorHelper.getDataSensoreForChart(
      concrete_structure_selected.sensors ?? [],
      type
    );
    return chart;
  };

  return (
    <>
      {chartData && (
        <IonCol size="6" className="chart">
          <IonRow>
            <IonCol></IonCol>
            <IonCol size="8" className="text-right">
              <span>
                sensor:
                <PopoverComponent
                  callback={(name: string) => {
                    const newName = name.replace("#", "");
                    let index = chartData.sensors!.findIndex(
                      (f) => f.name === newName
                    );
                    calculator(index);

                    // console.log("====================================");
                    // console.log(filter);

                    // console.log(name);
                    // console.log("====================================");
                  }}
                  title={chartData.sensor?.name}
                  not_allowed={false}
                  list={
                    chartData.sensors
                      ? chartData.sensors?.map((item) => "#" + item.name)
                      : []
                  }
                />
              </span>
            </IonCol>
          </IonRow>
          <SplineBarComponent
            model={chartData?.model}
            palettecolors={chartData?.palettecolors}
          />
          <p className="placeholder">{chartData.name}</p>
        </IonCol>
      )}
    </>
  );
};

export default LineBarSensorComponent;

import { IonCol, IonRouterLink, IonRow } from "@ionic/react";
import React, { useEffect, useState } from "react";
import "./notification-card.component.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  ConcreteModel,
  ConcreteSensorModel,
} from "../../models/concrete.model";
import { SensorHelper } from "../../helpers/sensor.helper";
import { globalState } from "../../store/slice/global.slice";
import { SensorTypeEnum } from "../../models/enums/sensor-type.enum";
import { updateStructureRequest } from "../../store/thunk/smart-concrete.thunk";

interface ContainerProps {
  callback?: Function;
  title?: string;
  structure?: ConcreteModel;
  sensore?: ConcreteSensorModel[];
}

class StatusModel {
  status?: JSX.Element;
  type?: SensorTypeEnum;
}

const NotificationCardComponent: React.FC<ContainerProps> = ({
  callback,
  title = "Smart Concrete",
  sensore,
  structure,
}) => {
  let sensorHelper = new SensorHelper();
  const { range } = useSelector(globalState);
  const [statusSensor, setStatusSensor] = useState<any[]>();
  const dispatch = useDispatch();

  useEffect(() => {
    let array: StatusModel[] = [];

    if (!structure?.sensors) return;

    let filter_structure_sen = structure?.sensors.filter((f) => f.see != true);
    let filter_structure = { ...structure, sensors: filter_structure_sen };

    let fully_cured = sensorHelper.fully_cured(filter_structure?.date ?? "");
    if (fully_cured.fully === "Fully Cured") {
      array.push({
        status: <span className="fully-cured">Is fully cured</span>,
        type: SensorTypeEnum.FULLY,
      });
    }

    let types = [SensorTypeEnum.PH, SensorTypeEnum.HUM, SensorTypeEnum.TEMP];
    types.forEach((type) => {
      let value = checkNormalRange(
        filter_structure?.sensors ?? [],
        type,
        filter_structure?.date ?? ""
      );

      if (value != undefined) {
        array.push({
          status: value,
          type: type,
        });
      }
    });
    if (array.length > 0) {
      setStatusSensor(array);
    }
  }, []);

  const checkNormalRange = (
    sensors: ConcreteSensorModel[],
    type: SensorTypeEnum,
    date: string
  ) => {
    if (range) {
      let average = sensorHelper.calc_sensors_average(sensors, type);

      let status = sensorHelper.checkNormalRangeWithClass(average, type, range);
      if (status === "") return undefined;

      if (status === "high") {
        return <span className="high">{type} is too high</span>;
      } else {
        return <span className="low">{type} is low</span>;
      }
    }
    return undefined;
  };

  const openStructure = (item: StatusModel) => {
    if (callback) callback();

    if (!structure?.sensors) return;

    if (!structure?.sensors) return;

    let sensors = [...structure.sensors];
    if (sensors) {
      let index = 0;
      sensors.forEach((element) => {
        if (element.type == item.type) {
          sensors[index] = { ...sensors[index], see: true };
        }
        index++;
      });
    }
    let newStructure = { ...structure, sensors: sensors };
    let route = "/view/statistical/" + structure?.id;
    dispatch(updateStructureRequest(newStructure, route));
  };

  const randomTime = () => {
    var hrs = Math.round(Math.random() * 24);
    var mins = Math.round(Math.random() * 60);
    var hFormat = hrs < 10 ? "0" : "";
    var mFormat = mins < 10 ? "0" : "";
    var amPm = hrs < 12 ? "AM" : "PM";
    var is12 = hrs % 12 === 0;

    return amPm === "AM" && !is12
      ? String(hFormat + hrs + ":" + mFormat + mins + " " + amPm)
      : "AM" && is12
      ? String(12 + ":" + mFormat + mins + " " + amPm)
      : is12
      ? String(hFormat + hrs + ":" + mFormat + mins + " " + amPm)
      : String(hFormat + (hrs - 12) + ":" + mFormat + mins + " " + amPm);
  };

  return (
    <div className="notification-card-style">
      {statusSensor &&
        statusSensor.map((item, index) => {
          return (
            <IonRouterLink
              key={structure?.id + (index + "")}
              onClick={() => openStructure(item)}
            >
              <IonRow>
                <IonCol>
                  <h4 className="m-0 pb-5">
                    {structure && structure.name}{" "}
                    {structure && structure.type?.title}
                  </h4>
                  <p>{item.status}</p>
                </IonCol>
                <IonCol size="2" className="text-right c-gray">
                  {randomTime()}
                </IonCol>
              </IonRow>
            </IonRouterLink>
          );
        })}
    </div>
  );
};

export default NotificationCardComponent;

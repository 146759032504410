import React from "react";
import "./status-card.component.scss";

interface ContainerProps {
  color?: string;
  s_color?: string;
  gradient_one?: string;
  gradient_two?: string;
  number_title?: string;
  number_subtitle?: string;
  title?: string;
  subtitle?: string;
}

export const StatusCardComponent: React.FC<ContainerProps> = ({
  gradient_one = "rgba(255, 230, 239, 0)",
  gradient_two = "#ffdee8",
  color,
  s_color,
  number_title,
  number_subtitle,
  title,
  subtitle,
}) => {
  return (
    <div className="">
      <div
        className="p-10 content m-5"
        style={{
          backgroundImage:
            "linear-gradient(to bottom, " +
            gradient_one +
            ", " +
            gradient_two +
            ")",
        }}
      >
        <h2 className="number_title" style={{ color: color }}>
          {number_title ?? "-"}
        </h2>
        <p className="sub_title" style={{ color: s_color }}>
          {number_subtitle ?? "-"}
        </p>
        <h5 className="title" style={{ color: color }}>
          {title ?? "-"}
        </h5>
        <p className="sub_title" style={{ color: s_color }}>
          {subtitle ?? "-"}
        </p>
      </div>
    </div>
  );
};

import React from "react";
import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonToast,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { ellipse, square, triangle } from "ionicons/icons";
import SearchPage from "./pages/search";
import HomePage from "./pages/home-container/home/home";
import NotificationPage from "./pages/notification";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/global.scss";
import { Menu } from "./components/menu/menu";
import AddNewStructurePage from "./pages/add-new-structure/add-new-structure";
import ViewStructurePage from "./pages/view-structure-container/view-structure/view-structure";
import SettingsPage from "./pages/settings/settings.page";
import { Provider } from "react-redux";

import store from "./store";
import DashboardPage from "./pages/home-container/dashboard/dashboard.page";
import HomeContainerPage from "./pages/home-container/home-container";
import ViewStructureContainerPage from "./pages/view-structure-container/view-structure-container";
import LoadingComponent from "./components/loading/loading.component";
import ToastComponent from "./components/toast/toast.component";

import CreateBrowserHistory from 'history/createBrowserHistory';
export const history = CreateBrowserHistory();

const App: React.FC = () => (
  <Provider store={store}>
    <IonApp>
      <IonReactRouter history={history}>
        <Menu />
        <LoadingComponent />
        <ToastComponent />
        
        <IonRouterOutlet>
          <Route path="/home" component={HomeContainerPage} />
          <Route path="/search" component={SearchPage} />
          <Route path="/add-new-structure" component={AddNewStructurePage} />
          <Route path="/view" component={ViewStructureContainerPage} />
          <Route path="/settings" component={SettingsPage} />
          <Redirect exact from="/" to="/home" />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  </Provider>
);

export default App;

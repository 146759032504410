import React from "react";
import { useSelector } from "react-redux";
import { globalState } from "../../store/slice/global.slice";
import "./loading.component.scss";

interface ContainerProps {}

const LoadingComponent: React.FC<ContainerProps> = ({}) => {
  const global_state = useSelector(globalState);

  return (
    <>
      {global_state && global_state.isLoading === true ? (
        <div className="spinner"></div>
      ) : null}
    </>
  );
};

export default LoadingComponent;

import { concrete_structure_url } from '../../core/url';
import axios from "../../core/axios";
import { ResponseModel } from "../../models/response.model";
import { hasError, setRange, startLoading, stopLoading } from "../slice/global.slice";
import { ConcreteStructureDataModel } from '../../models/concrete-structure.model';
import { setConcreteStructureData } from '../slice/smart-concrete.slice';

export function concreteStructureTypeList() {
    return async (dispatch: any) => {
        dispatch(startLoading());
        try {
            let response = await axios.get<ResponseModel<ConcreteStructureDataModel>>(
                concrete_structure_url
            );
            let data = response.data;

            if(data.result) {
                dispatch(setConcreteStructureData(data.result));
                dispatch(setRange(data.result?.config));
            }

            dispatch(stopLoading());

        } catch (error) {

            dispatch(stopLoading());
            dispatch(hasError({ message: "error" }))

        }
    };
}

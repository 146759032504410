import { IonCol, IonRow } from "@ionic/react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ConcreteSensorModel } from "../../../models/concrete.model";
import { globalState, setNotifiation, setSensorModal } from "../../../store/slice/global.slice";
import { concreteState } from "../../../store/slice/smart-concrete.slice";
import ModalComponent from "../../modal/modal.component";
import SpaceComponent from "../../space/space.component";
import SensorsAddComponent from "../sensors-add/sensors-add.component";
import SensorsRowComponent from "../sensors.row/sensors.row.component";
import "./sensors.grid.component.scss";

interface ContainerProps {}

const SensorsGridComponent: React.FC<ContainerProps> = () => {
  const [isEditable, setIsEditable] = useState(-1);
  const global_state = useSelector(globalState);
  const dispatch = useDispatch();
  const { concrete_structure_selected } = useSelector(concreteState);
  
  
  const [showModal, setShowModal] = useState(-1);

  const add_sensor = () => {
    // setShowModal(true);
    setIsEditable(-1);
    dispatch(setSensorModal(true));
  };

  const onEdit = (index: number) => {
    setIsEditable(index);
    dispatch(setSensorModal(true));
  };

  return (
    <div className="sensors-grid-style">
      <IonRow className="flex">
        <IonCol>
          <p>Sensors:</p>
        </IonCol>
        <IonCol size="4" className="text-right">
          <button className="button-border general-config">
            general config
          </button>
        </IonCol>
        <IonCol size="5" className="text-right">
          <button className="button-border" onClick={add_sensor}>
            add a new sensor
          </button>
        </IonCol>
      </IonRow>

      <ModalComponent
        showModal={global_state ? global_state.sensorModal : false}
        onDidDismiss={() => dispatch(setSensorModal(false))}
        Component={
          <div>
            <SensorsAddComponent indexForEdit={isEditable} />
          </div>
        }
      />

      <SpaceComponent />

      {concrete_structure_selected && concrete_structure_selected.sensors && (
        <IonRow className="header">
          <IonCol size="1">#</IonCol>
          <IonCol size="3">Sensor name</IonCol>
          <IonCol size="3">Description</IonCol>
          <IonCol size="1">Type</IonCol>
          <IonCol size="4">Actions</IonCol>
        </IonRow>
      )}
      <>
        {concrete_structure_selected &&
          concrete_structure_selected.sensors &&
          concrete_structure_selected.sensors.map(
            (item: ConcreteSensorModel, index: number) => {
              return (
                <SensorsRowComponent
                  key={index}
                  onEdit={onEdit}
                  model={item}
                  index={index}
                />
              );
            }
          )}
      </>
      {/* <SensorsRowComponent />
      <SensorsRowComponent /> */}
    </div>
  );
};

export default SensorsGridComponent;

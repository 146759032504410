import { ChartBarModel } from "../components/chart/bar/chart-bar.model";
import { ConcreteModel, ConcreteSensorModel } from "../models/concrete.model";
import { RaneStausEnum } from "../models/enums/range-status.model";
import { SensorTypeEnum } from "../models/enums/sensor-type.enum";
import { SensorTypeRangeModel } from "../models/sensor-type-range.model";

export class SensorHelper {

    calc_sensors_average = (sensors: ConcreteSensorModel[], type: SensorTypeEnum) => {

        if (sensors.length === 0) return "-";

        let _sensors = sensors.filter(f => f.type === type);
        if (_sensors.length > 0) {
            let average = 0;
            _sensors.forEach(element => {
                if (element.data && element.data.length > 0) {
                    let item = Number(element.data[element.data.length - 1]);
                    if (item) {
                        average += item;
                    }
                }
            });
            let sum = average / _sensors.length;
            sum = Math.round(sum);
            if (average !== 0) return (sum) + "";
        }

        return "-";

    }

    // calc_sensor_average = (sensor: ConcreteSensorModel, type: SensorTypeEnum) => {

    //     let average = 0;
    //     sensor.forEach(element => {
    //         if (element.data && element.data.length > 0) {
    //             let item = Number(element.data[element.data.length - 1]);
    //             if (item) {
    //                 average += item;
    //             }
    //         }
    //     });
    //     if (average !== 0) return (average / sensor.length) + "";

    // }


    fully_cured = (date: string) => {
        let _date = new Date(date).getTime();
        let _now = new Date().getTime();

        var delta = Math.abs(_now - _date) / 1000;

        // calculate (and subtract) whole days
        var days = Math.floor(delta / 86400);

        if (days > 28) {
            return { fully: "Fully Cured", day: "" };
        }
        return { fully: "", day: ((28 - days) + "") };
    }

    getDataSensoreForChart = (sensors: ConcreteSensorModel[], type: SensorTypeEnum, index: number = 0) => {

        // let numberOfSensor = 0;
        // let _sensor: ConcreteSensorModel[] = [];

        let _sensors = sensors.filter(f => f.type === type);

        // if (_sensors.length > 0) {
        //     _sensor = _sensors[index] ?? [];
        //     numberOfSensor = _sensors.length;
        // }

        return {
            numberOfSensor: _sensors.length,
            sensor: _sensors
        }

    }

    /**
     * 
     */
    generateChartData = (numbers: number[]) => {

        let model: ChartBarModel = {
            data: []
        }

        // ChartBarDataModel
        numbers.forEach((number, index) => {
            model.data.push({
                label: (index + 1).toString(),
                value: Number(number),
            })
        })

        return model;
    }

    generateChartWithSensorData = (numbers: ConcreteSensorModel) => {

        let model: ChartBarModel = {
            data: []
        }

        // ChartBarDataModel
        numbers.data?.forEach((number, index) => {

            let label = index + 1 == numbers.data?.length ? "today" : (index + 1).toString();

            model.data.push({
                label: label,
                value: Number(number),
            })
        })

        return model;
    }

    checkNormalRange = (average: number, type: SensorTypeEnum, range: SensorTypeRangeModel) => {

        let min = 0;
        let max = 0;

        if (type === SensorTypeEnum.HUM) {
            min = range.humidity?.min ?? 0;
            max = range.humidity?.max ?? 0;
        } else if (type === SensorTypeEnum.PH) {
            min = range.ph?.min ?? 0;
            max = range.ph?.max ?? 0;
        } else if (type === SensorTypeEnum.TEMP) {
            min = range.temperature?.min ?? 0;
            max = range.temperature?.max ?? 0;
        }
        // console.log('==================================== min <= average && average <= max' );
        // console.log(range);
        // console.log(type);
        // console.log(min);
        // console.log(average);
        // console.log(max);
        // console.log('====================================');
        if (min <= average && average <= max) {
            return RaneStausEnum.normal;
        } else if (average < min) {
            return RaneStausEnum.low;
        }
        return RaneStausEnum.high;
    }

    checkNormalRangeWithClass = (number: string, type: SensorTypeEnum, range: SensorTypeRangeModel) => {
        if (number !== "-" && range) {
            let rangeStatus = this.checkNormalRange(Number(number), type, range);
            if (rangeStatus === RaneStausEnum.high) {
                return "high"
            } else if (rangeStatus === RaneStausEnum.low) {
                return "low"
            }
        }
        return "";
    }


    countOfStructursHigh = (structurs: ConcreteModel[], type: SensorTypeEnum, range: SensorTypeRangeModel) => {
        let count = 0;
        structurs.forEach(element => {
            let average = this.calc_sensors_average(element.sensors ?? [], type);
            if (average !== "-") {
                let status = this.checkNormalRange(Number(average), type, range);
                if (status === RaneStausEnum.high) {
                    count += 1;
                }
            }
        });
        return count;
    }

    countOfStructursfully_cured = (structurs: ConcreteModel[]) => {
        let count = 0;
        structurs.forEach(element => {
            let is_fully = this.fully_cured(element.date ?? "");
            if (is_fully.fully !== "") {
                count += 1;
            }
        });
        return count;
    }

    avergeAllSensorData = (concrete_structure: ConcreteModel[], type: SensorTypeEnum) => {

        let sensors: ConcreteSensorModel[] = [];
        concrete_structure.forEach(Element => {
            sensors = [...sensors, ...(Element.sensors ?? [])];
        })

        let _sensors = sensors.filter(f => f.type === type);

        let max_array = 0;
        _sensors.forEach(element => {
            if (element.data) {
                if (element.data?.length > max_array) {
                    max_array = element.data?.length
                }
            }
        });
        let array: number[] = [];
        for (let index = 0; index < max_array; index++) {
            array[index] = 0;
        }

        _sensors.forEach(element => {
            for (let index = 0; index < max_array; index++) {
                // const element = array[index];
                if (element.data) {
                    if (element.data[index]) {
                        array[index] += element.data[index];
                    }
                }
            }

        });

        // average
        for (let index = 0; index < array.length; index++) {
            array[index] = array[index] / _sensors.length;
        }


        return array;

    }



}
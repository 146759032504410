import React from "react";

interface ContainerProps {
  space?: number;
}

const SpaceComponent: React.FC<ContainerProps> = ({ space = 30 }) => {
  return <div style={{ height: space + "px" }}></div>;
};

export default SpaceComponent;

import {
  ActionSheetButton,
  IonActionSheet,
  IonCol,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonRow,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { cleanup } from "@testing-library/react";
import {
  archiveSharp,
  caretBack,
  caretDown,
  caretForwardCircle,
  chevronDown,
  heart,
  share,
  trash,
} from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ASSETSURL } from "../../../core/url";
import { setNotifiation } from "../../../store/slice/global.slice";
import "./select.component.scss";
import { SelectModel } from "./select.model";

interface ContainerProps {
  onEndedChange?: Function;
  list?: SelectModel[];
  onSave?: Function;
  value?: SelectModel;
  title?: string;
  hasImage?: boolean;
  isActionSheet?: boolean

}

const SelectComponent: React.FC<ContainerProps> = ({
  onEndedChange,
  list,
  onSave,
  value,
  title = "Type of concrete structure",
  hasImage = true,
  isActionSheet= false
}) => {
  // const [select, setSelect] = useState<SelectModel>();
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [buttons, setButtons] = useState<(ActionSheetButton | string)[]>([]);
  const dispatch = useDispatch();
  
  const open = () => {
    if(isActionSheet == true) {
      setShowActionSheet(true);
    } else if(onEndedChange) {
      onEndedChange();
    } else {
      dispatch(setNotifiation(true));
    }
  };

  const onEnded = (value: SelectModel) => {
    if (onEndedChange) {
      onEndedChange(value);
    }
  };

  const save = () => {
    if (onSave) {
      onSave(value);
    }
  };
  const cancel = () => {
    if (onSave) {
      onSave();
    }
  };

  // useEffect(() => {
  //   console.log('====================================value');
  //   console.log(value);
  //   console.log('====================================');
  // }, [value])

  useEffect(() => {
    if (buttons.length == 0 && isActionSheet == true) {
      var buttons_action: (ActionSheetButton | string)[] = [];
      list?.forEach((item) => {
        buttons_action.push({
          text: item.title,
          role: "destructive",
          handler: () => {
            // setSelect(item);
            onEnded(item);
          },
        });
      });
      setButtons(buttons_action);
    }
  }, [list]);

  return (
    <div className={(onSave ? " border_edit" : "") + " cursor"}>
      <p>{title ?? ""}</p>

      {/* <IonSelect placeholder="Select One">
              <IonSelectOption value="female">
                <h1>amin</h1>
                <img src="http://localhost:8000/assets/structure-type/factory.svg" alt=""/>
              </IonSelectOption>
              <IonSelectOption value="male">Male</IonSelectOption>
      </IonSelect> */}
            
      <IonRow className="back-input mt-5" onClick={open}>
        {value && value?.icon && (
          <IonCol size="1">
            {/* <IonImg src="assets/icon/structures/house.svg" /> */}
            <IonImg src={value?.icon} />
          </IonCol>
        )}
        <IonCol><span className="pl-10">{value?.title}</span></IonCol>
        <IonCol size="1" className="text-right">
          <IonIcon icon={caretDown} />
        </IonCol>
      </IonRow>
      <IonActionSheet
        isOpen={showActionSheet}
        onDidDismiss={() => setShowActionSheet(false)}
        cssClass=""
        buttons={
          buttons
          //   [
          //   {
          //     text: "Delete",
          //     role: "destructive",
          //     icon: trash,
          //     handler: () => {
          //       setText("Delete clicked");
          //       console.log("Delete clicked");
          //     },
          //   },
          //   {
          //     text: "Share",
          //     icon: share,
          //     handler: () => {
          //       console.log("Share clicked");
          //     },
          //   },
          //   {
          //     text: "Play (open modal)",
          //     icon: caretForwardCircle,
          //     handler: () => {
          //       console.log("Play clicked");
          //     },
          //   },
          //   {
          //     text: "Favorite",
          //     icon: heart,
          //     handler: () => {
          //       console.log("Favorite clicked");
          //     },
          //   },
          //   {
          //     text: "Cancel",
          //     // icon: close,
          //     role: "cancel",
          //     handler: () => {
          //       console.log("Cancel clicked");
          //     },
          //   },
          // ]
        }
      ></IonActionSheet>
      {onSave && (
        // <IonIcon icon={checkmarkCircle} />
        <>
          <a className="button-border save" onClick={save}>
            save
          </a>
          <a className="button-border cancel" onClick={cancel}>
            cancel
          </a>
        </>
      )}
    </div>
  );
};

export default SelectComponent;

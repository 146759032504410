import { IonImg } from "@ionic/react";
import React from "react";
import "./map.component.scss";

interface ContainerProps {
  title?: string;
  onSave?: Function;
  value?: string;

}

const MapComponent: React.FC<ContainerProps> = ({ title = "Location", onSave, value }) => {

  const save = () => {
    if (onSave) {
      onSave();
    }
  };
  const cancel = () => {
    if (onSave) {
      onSave();
    }
  };

  return (
    <div className={"map-component-style" + (onSave ? " border_edit" : "")}>
      <p>{title}</p>
      <IonImg src="assets/map.jpg" />
      {onSave && (
        // <IonIcon icon={checkmarkCircle} />
        <>
          <button className="button-border save" onClick={save}>
            save
          </button>
          <button className="button-border cancel" onClick={cancel}>
            cancel
          </button>
        </>
      )}
    </div>
  );
};

export default MapComponent;

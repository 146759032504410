import { IonCol, IonImg, IonRow } from "@ionic/react";

import React from "react";
import "./viewer-field.component.scss";

interface ContainerProps {
  onEditMode?: Function;
  description?: string;
  image_src?: string;
  value?: string;
  image?: string;
}

const ViewerFieldComponent: React.FC<ContainerProps> = ({
  onEditMode,
  description,
  image_src,
  value,
  image,
}) => {
  const edit = () => {
    if (onEditMode) {
      onEditMode();
    }
  };

  return (
    <div className="viewer-field-style">
      <IonRow className="">
        <IonCol className="description pb-10">
          <p>{description}</p>
        </IonCol>
        <IonCol size="3" className="text-right" onClick={edit}>
          <button className="button-border edit">edit</button>
        </IonCol>
      </IonRow>

      {!image ? (
        <IonRow className="flex">
          {image_src && (
            <IonCol size="1" style={{ marginRight: 10 }}>
              <IonImg src={image_src} />
            </IonCol>
          )}
          <IonCol>{value}</IonCol>
        </IonRow>
      ) : (
        <IonImg src={image} />
      )}
    </div>
  );
};

export default ViewerFieldComponent;

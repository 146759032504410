import React from "react";
import {
  IonContent,
  IonItem,
  IonIcon,
  IonLabel,
  IonPage,
  IonImg,
} from "@ionic/react";
import { close } from "ionicons/icons";
import "./settings.page.scss";
import HeaderComponent from "../../components/header/header.component";
import SpaceComponent from "../../components/space/space.component";
import { JSONHelper } from "../../helpers/json.helper";
import { useDispatch } from "react-redux";
import { setToast } from "../../store/slice/global.slice";
import { updateConfigRequest } from "../../store/thunk/smart-concrete.thunk";

const SettingsPage = () => {
  const dispatch = useDispatch();

  const config = (e: any) => {
    let jsonHelper = new JSONHelper();
    // reset file
    let files = { ...e.target.files };
    e.target.value = null;

    jsonHelper
      .convertJsonConfig(files)
      .then((response) => {
        if (response) {
          console.log(response);
          
          dispatch(updateConfigRequest(response));
        }
      })
      .catch((e) => {
        console.log(e);
        
        dispatch(setToast({ message: e, status: false }));
      });
  };

  return (
    <IonPage>
      <IonContent className="mobile-p-0">
        <HeaderComponent isBack={true} title="Settings" />
        <div className="support_desktop">
          {/* <IonItem lines="none">
            <IonIcon icon={close} size="large" />
          </IonItem> */}
          <SpaceComponent space={20} />
          <IonItem lines="none">
            <IonImg src="assets/icon/settings/color-filter-outline.svg" />
            <IonLabel className="ml-20">Theme</IonLabel>
            {/* <IonIcon slot="start" icon={colorFilterOutline} size="large" /> */}
          </IonItem>
          <IonItem lines="none">
            <IonImg src="assets/icon/settings/shield.svg" />
            <IonLabel className="ml-20">Security</IonLabel>
            {/* <IonIcon slot="start" icon={shield} size="large" /> */}
          </IonItem>
          <IonItem lines="none">
            <IonImg src="assets/icon/settings/notifications-outline.svg" />
            <IonLabel className="ml-20">Notification configuration</IonLabel>
            {/* <IonIcon slot="start" icon={notificationsOutline} size="large" /> */}
          </IonItem>
          <IonItem lines="none">
            <IonImg src="assets/icon/settings/ic-storage.svg" />

            <IonLabel className="ml-20">Data and Storage</IonLabel>
            {/* <IonIcon slot="start" icon={exit} size="large" /> */}
          </IonItem>
          <IonItem lines="none">
            <IonImg src="assets/icon/settings/system-log.svg" />

            <IonLabel className="ml-20">System log</IonLabel>
            {/* <IonIcon slot="start" icon={exit} size="large" /> */}
          </IonItem>

          <IonItem lines="none">
            <IonImg src="assets/icon/settings/general-sensor.svg" />
            <IonLabel className="ml-20">
              <label>
                <input
                  id="choseFile"
                  type="file"
                  onChange={config}
                  accept=".json"
                  style={{ display: "none" }}
                />
                General sensor configuration
              </label>
              <span className="s-size c-gray"><br/>upload config ...</span>
            </IonLabel>
            {/* <IonIcon slot="start" icon={exit} size="large" /> */}
          </IonItem>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SettingsPage;

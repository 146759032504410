import {
  IonCol,
  IonIcon,
  IonImg,
  IonRouterLink,
  IonRow,
  NavContext,
} from "@ionic/react";
import React, { useCallback, useContext, useState } from "react";
import "./header.component.scss";
import { menuController } from "@ionic/core";
import { chevronBack, ellipsisVertical } from "ionicons/icons";

interface ContainerProps {
  callback?: Function;
  title?: string;
  title_icon?: string;
  isBack?: boolean;
  rightIcon?: string;
  rightCallBack?: Function;
  rightIconIsPopover?: boolean;
  RightComponent?: JSX.Element;
}

const HeaderComponent: React.FC<ContainerProps> = ({
  callback,
  isBack = false,
  title = "",
  title_icon,
  rightIcon,
  rightCallBack,
  rightIconIsPopover = false,
  RightComponent,
}) => {
  const { goBack } = useContext(NavContext);

  const openMenu = async () => {
    // alert(1);
    menuController.open("toolsMenu");
    // await menuController.enable(true,"toolsMenu")
  };

  // const redirect = useCallback(
  //   () => navigate('/new/address', 'back'),
  //   [navigate]
  // );

  const back = () => {
    goBack("/home");
  };

  const rightAction = () => {
    if (rightCallBack) {
      rightCallBack();
    }
  };

  return (
    <IonRow className="header-theme fixed">
      <IonCol size="1" className="cursor flex j-start">
        {isBack ? (
          <IonIcon icon={chevronBack} onClick={back} size="large" />
        ) : (
          <IonImg
            src="assets/icon/menu.svg"
            className="menu-icon"
            onClick={openMenu}
          />
        )}
      </IonCol>

      <IonCol className="title_style">
        <IonRow>
          <IonCol className="bold m-0 text-center">
            <div className="flex">
              {title_icon && <IonImg className="title_icon" src={title_icon} />}
              <h3 className="title">{title}</h3>
            </div>
          </IonCol>
        </IonRow>
      </IonCol>

      <IonCol size="1" className="text-right flex">
        {RightComponent && RightComponent}
      </IonCol>

      {/* {title ? (
        <h5 className="bold m-0">{title}</h5>
      ) : (
        <IonImg src="assets/icon/logo-and-typography.svg" />
      )} */}

      {/* <IonImg
        src="assets/icon/menu.svg"
        className="menu-icon"
        onClick={openMenu}
      /> */}
    </IonRow>
  );
};

export default HeaderComponent;

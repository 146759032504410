import { ChartBarModel } from "../../../components/chart/bar/chart-bar.model";

export const dataMonthlyChartBar: ChartBarModel = {
    name: "Avarage of PH",
    yAxisMaxValue: 15,
    data: [
        {
            label: "Apr",
            value: 7,
        },
        {
            label: "May",
            value: 14,
        },
        {
            label: "Jun",
            value: 9,
        },
        {
            label: "Jul",
            value: 10,
        },
        {
            label: "Aug",
            value: 8,
        },
    ],
};

export const dataDailyChartBar: ChartBarModel = {
    name: "Avarage of PH",
    yAxisMaxValue: 50,
    data: [
        {
            label: "Sun",
            value: 11,
        },
        {
            label: "Mon",
            value: 33,
        },
        {
            label: "Tue",
            value: 45,
        },
        {
            label: "Thur",
            value: 22,
        },
        {
            label: "Fri",
            value: 34,
        },
    ],
};


export const data2MonthlyChartBar: ChartBarModel = {
    name: "Avarage of HL",
    yAxisMaxValue: 12,
    data: [
        {
            label: "Apr",
            value: 4,
        },
        {
            label: "May",
            value: 6,
        },
        {
            label: "Jun",
            value: 2,
        },
        {
            label: "Jul",
            value: 11,
        },
        {
            label: "Aug",
            value: 5,
        },
    ],
};

export const data2DailyChartBar: ChartBarModel = {
    name: "Avarage of HL",
    yAxisMaxValue: 80,
    data: [
        {
            label: "Sun",
            value: 25,
        },
        {
            label: "Mon",
            value: 54,
        },
        {
            label: "Tue",
            value: 33,
        },
        {
            label: "Thur",
            value: 64,
        },
        {
            label: "Fri",
            value: 75,
        },
    ],
};


export const dataMonthlyChartPie: ChartBarModel = {
    name: "12 to 19 Apr",
    yAxisMaxValue: 80,
    initialValue: 15,
    data: [
        {
            label: "too high PH",
            value: 15,
            isSliced: true,
            // labelBgColor: "#0000ff"
        },
        {
            label: "too high PH2",
            value: 44,
        },
        {
            label: "too high PH3",
            value: 33,
        },
    ],
};

export const dataWeeklyChartPie = (name: string, ph: number, hum: number, temp: number) : ChartBarModel => {
    return {
        name: name,
        // yAxisMaxValue: 80,
        initialValue: ph,
        data: [
            {
                label: "too high PH",
                value: ph,
                isSliced: true,
                hoverColor: "#ed135b",
                // valueFontColor: "#ffffff",
                // valueBgColor: "#0000ff",
                color: "#ed135b"
                
                // valueBorderColor: "#ff0000"

            },
            {
                label: "too high HUM",
                value: hum,
                hoverColor: "#4791ff",
                
                // valueFontColor: "#ffffff",
                // valueBgColor: "#000000",
                // valueBorderColor: "#ff0000"
                color: "#4791ff"

            },
            {
                label: "too high TEMP",
                value: temp,
                hoverColor: "#02bc77",
                // valueFontColor: "#ffffff",
                // valueBgColor: "#000000",
                // valueBorderColor: "#ff0000",
                color: "#02bc77"

            },
        ],
    }
};

import React, { useState } from "react";
import "./segment.component.scss";

/**
 * برای گرادینت
 * https://cssgradient.io/
 */
interface ContainerProps {
  callback?: Function;
  tabs: string[];
  activeTab?: string;
}

const SegmentComponent: React.FC<ContainerProps> = ({
  callback,
  tabs,
  activeTab,
}) => {
  const [active, setActive] = useState(
    activeTab ? activeTab : tabs ? tabs[0] : ""
  );

  const active_action = (name: string) => {
    setActive(name);
    if (callback) {
      callback(name.toLowerCase());
    }
  };

  return (
    <div className="segment">
      <ul className="">
        {tabs &&
          tabs.map((item, index) => {
            return (
              <li
                className={"cursor text-center" + (active === item ? " active" : "")}
                onClick={() => active_action(item)}
                key={index}
              >
                {item}
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default SegmentComponent;

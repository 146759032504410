import { IonIcon, IonPopover } from "@ionic/react";
import React, { useState } from "react";
import "./popover.component.scss";
import { chevronDown } from "ionicons/icons";

/**
 * لیست آیکن‌های مربوط به ionic رو میاره
 * node_modules/ionicons/icons/index.d.ts
 * https://ionicons.com/
 */

interface ContainerProps {
  callback?: Function;
  list: string[];
  title?: string;
  not_allowed?: boolean;
}

const PopoverComponent: React.FC<ContainerProps> = ({
  callback,
  list,
  title = "all",
  not_allowed = true
}) => {

  const [selected, setSelected] = useState(title);
  
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });
  const chose = (name: string) => {
    setShowPopover({ showPopover: false, event: undefined });
    setSelected(name);
    if (callback) {
      callback(name);
    }
  };
  return (
    <div className="popover-theme flex">
      <IonPopover
        cssClass=""
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={() =>
          setShowPopover({ showPopover: false, event: undefined })
        }
      >
        <ul>
          {list.map((item, index) => {
            return (
              <li key={index * 932} onClick={() => chose(item)}>
                {item}
              </li>
            );
          })}
        </ul>
      </IonPopover>
      <p
        className="popover"
        onClick={(e: any) => {
          if(not_allowed == true) return;
          e.persist();
          setShowPopover({ showPopover: true, event: e });
        }}
      >
        {selected}
      <IonIcon className="popover-icon" icon={chevronDown} />
      </p>
    </div>
  );
};

export default PopoverComponent;

import {
  IonCol,
  IonDatetime,
  IonIcon,
  IonRow,
} from "@ionic/react";
import { calendarClearOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import "./date.component.scss";
// import { SelectModel } from "./date.model";

interface ContainerProps {
  onEndedChange?: Function;
  onSave?: Function;
  value?: string;
  title?: string;
}

const DateComponent: React.FC<ContainerProps> = ({
  onEndedChange,
  onSave,
  value,
  title,
}) => {
  // const [select, setSelect] = useState<SelectModel>();
  // const [showActionSheet, setShowActionSheet] = useState(false);
  // const [buttons, setButtons] = useState<(ActionSheetButton | string)[]>([]);
  const [selectedDate, setSelectedDate] = useState<string>();

  const open = () => {};

  // const onEnded = () => {
  //   if (onEndedChange) {
  //     onEndedChange(select);
  //   }
  // };

  useEffect(() => {
    setSelectedDate(value ?? "");
  }, [value]);

  const save = () => {
    if (onSave) {
      onSave(selectedDate);
    }
  };
  const cancel = () => {
    if (onSave) {
      onSave();
    }
  };

  return (
    <div className={onSave ? " border_edit" : ""}>
      <p>{title}</p>
      <IonRow className="back-input" onClick={open}>
        <IonCol className="pl-10">
          <IonDatetime
            pickerOptions={{
              buttons: [
                {
                  text: "select",
                  handler: (calendar) => {
                    // console.log(calendar);
                    let day =
                      calendar.day.value > 9
                        ? calendar.day.value
                        : "0" + calendar.day.value;
                    let month =
                      calendar.month.value > 9
                        ? calendar.month.value
                        : "0" + calendar.month.value;
                    let year = calendar.year.value;
                    let date = year + "/" + month + "/" + day;
                    setSelectedDate(date);
                    if (onEndedChange) {
                      onEndedChange(date);
                    }
                  },
                },
                {
                  text: "close",
                  handler: () => {
                    // console.log("Clicked Log. Do not Dismiss.");
                    return true;
                  },
                },
              ],
            }}
            placeholder="select date"
            displayFormat="YYYY/MM/DD"
            // min="1981"
            // max="2002"
            value={selectedDate}
          ></IonDatetime>
        </IonCol>
        <IonCol size="1">
          <IonIcon icon={calendarClearOutline} />
        </IonCol>
      </IonRow>
      {onSave && (
        // <IonIcon icon={checkmarkCircle} />
        <>
          <button className="button-border save" onClick={save}>
            save
          </button>
          <button className="button-border cancel" onClick={cancel}>
            cancel
          </button>
        </>
      )}
    </div>
  );
};

export default DateComponent;

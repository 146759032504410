import { ToastModel } from './../../models/error.model';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorModel } from "../../models/error.model";
import { SensorTypeRangeModel } from '../../models/sensor-type-range.model';
// import { UserModel } from '../../models/users.model'

export interface GlobalSliceModel {
  notification: boolean;
  sensorModal: boolean;
  isLoading: boolean;
  showSearch: boolean;
  clearFilter: boolean;
  error: ErrorModel | undefined;
  toast: ToastModel | undefined;
  range: SensorTypeRangeModel | undefined;
}

const initialState: GlobalSliceModel = {
  notification: false,
  sensorModal: false,
  isLoading: false,
  showSearch: false,
  clearFilter: true,
  error: undefined,
  toast: undefined,
  range: undefined
};


const GlobalSlice = createSlice({
  name: "GlobalSlice",
  initialState,
  reducers: {
    setNotifiation(state, { payload }) {
      state.notification = payload;
    },
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    hasError: (state, action: PayloadAction<ErrorModel | undefined>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    setToast: (state, action: PayloadAction<ToastModel | undefined>) => {
      state.isLoading = false;
      state.toast = action.payload;
    },
    resetError: (state) => {
      state.error = undefined;
    },
    resetToast: (state) => {
      state.toast = undefined;
    },
    setRange: (state, action: PayloadAction<SensorTypeRangeModel | undefined>) => {
      state.range = action.payload;
    },
    setShowSearch: (state, action: PayloadAction<boolean>) => {
      state.clearFilter = false;
      state.showSearch = action.payload;
    },
    setClearFilter: (state, action: PayloadAction<boolean>) => {
      state.clearFilter = action.payload;
    },
    setSensorModal: (state, action: PayloadAction<boolean>) => {
      state.sensorModal = action.payload;
    },
  },
});

export const globalState = (state: any) => state.global;

export const {setClearFilter,  setNotifiation, startLoading, stopLoading, hasError, resetError, setToast, resetToast, setRange, setShowSearch, setSensorModal } = GlobalSlice.actions;
export default GlobalSlice.reducer;

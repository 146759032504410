import { IonTextarea } from "@ionic/react";
import React, { useEffect, useState } from "react";
import "./input-textarea.component.scss";

interface ContainerProps {
  onEndedChange?: Function;
  title?: string;
  isOptional?: boolean;
  onSave?: Function;
  value?: string;
  row?: number;
}

const InputAreaComponent: React.FC<ContainerProps> = ({
  onEndedChange,
  title,
  isOptional,
  onSave,
  value,
  row = 6
}) => {
  const [text, setText] = useState<string>();

  useEffect(() => {
    setText(value ?? "")
  }, [value])


  const onEnded = () => {
    if (onEndedChange) {
      onEndedChange(text);
    }
  };

  const save = () => {
    if (onSave) {
      onSave(text);
    }
  };
  const cancel = () => {
    if (onSave) {
      onSave();
    }
  };

  return (
    <div className={onSave ? " border_edit" : ""}>
      <p>
        {title}
        {isOptional && <span className="c-gray"> (optional)</span>}
      </p>
      <IonTextarea
        value={text}
        // placeholder="Enter Input"
        onIonChange={(e) => setText(e.detail.value!)}
        onIonBlur={onEnded}
        rows={row}
      ></IonTextarea>
      {onSave && (
        // <IonIcon icon={checkmarkCircle} />
        <>
          <a className="button-border save" onClick={save}>
            save
          </a>
          <a className="button-border cancel" onClick={cancel}>
            cancel
          </a>
        </>
      )}
    </div>
  );
};

export default InputAreaComponent;
